import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function VentaSalderos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_COMPRADORES = process.env.REACT_APP_URL_COMPRADORES;
  const URL_PEDIDOS_CLIENTE = process.env.REACT_APP_URL_PEDIDOS_CLIENTE;
  const URL_ARTICULOS_PEDIDO_CLIENTE = process.env.REACT_APP_URL_ARTICULOS_PEDIDO_CLIENTE;
  const URL_SOCIEDADES = process.env.REACT_APP_URL_SOCIEDADES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  // let hoy = new Date()
  // let inicio = new Date()
  // inicio.setDate(inicio.getDate()-30);
 
  // let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  // let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [pedidoCliente, setPedidoCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [comprador, setComprador] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [value, setValue] = useState("");

  const [idArticulo, setIdArticulo] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState(hoy);
  const [fecha_cancelacion, setFechaCancelacion] = useState("");
  const [fecha_confirmacion, setFechaConfirmacion] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [compradores, setCompradores] = useState([]);
  const [selectedComprador, setSelectedComprador] = useState("");
  const [sociedades, setSociedades] = useState([]);
  const [selectedSociedad, setSelectedSociedad] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      tallas: "",
      colores: "",
      cantidad: 0,
      precio: 0,
      total: 0,
      articulosNombre: ""
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_EMPRESAS, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allEmpresa = response.data;
          setEmpresas(allEmpresa);
          //
        })
        .catch((err) => {
          console.log(err);
        });
        axios
          .get(URL_COMPRADORES, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allCompradores = res.data;
            setCompradores(allCompradores);
          })
          .catch((err) => {
            console.log(err);
          });
          axios
          .get(URL_SOCIEDADES, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allSociedades = res.data;
            setSociedades(allSociedades);
          })
          .catch((err) => {
            console.log(err);
          });
  }, []);

  const savePedido = async (event) => {
    event.preventDefault();
        if (selectedCliente != "") {
          setValidaBoton(false);
          toggleProgreso();
          let totalPedido = inputFields.length;
          try {
            await axios
              .post(
                URL_PEDIDOS,
                {
                  fecha,
                  fechaCreacion: hoy,
                  resurtido: "No",
                  empresas: selectedEmpresa,
                  clientes: selectedCliente,
                  colaboradores: selectedColaborador,
                  fecha_cancelacion,
                  fecha_confirmacion,
                  total_general,
                  total_piezas,
                  compradores: selectedComprador,
                  observaciones,
                  articulos: "65135444c2c4d12b8790d4e6",
                  pedidoCliente: "NA",
                  surtido: 0,
                  pendiente_surtir: total_piezas,
                  confirmacionPedido: "No",
                  status: "Abierto",
                  fechaEstCodigos: hoy,
                  fechaEstFicha: hoy,
                  fechaEstAuditoria: hoy,
                  fechaEstMuestraFit: hoy,
                  fechaEstMuestraConfirmacion: hoy,
                  fechaEstEtiquetas: hoy,
                  fechaRealCodigos: hoy,
                  fechaRealFicha: hoy,
                  fechaRealAuditoria: hoy,
                  fechaRealMuestraFit: hoy,
                  fechaRealMuestraConfirmacion: hoy,
                  fechaRealEtiquetas: hoy,
                  autorizacionFitFecha: hoy,
                  autorizacionCostosFecha: hoy,
                  autorizacionDisenoFecha: hoy,
                  autorizacionCalidadFecha: hoy,
                  autorizacionPloterFecha: hoy,
                  autorizacionPloterDisenoFecha: hoy,
                  autorizacionSurtirFecha: hoy,

                  autorizacionFitColaborador: "613a9434225bce3df050c220",
                  autorizacionCostosColaborador: "613a9434225bce3df050c220",
                  autorizacionDisenoColaborador: "613a9434225bce3df050c220",
                  autorizacionCalidadColaborador: "613a9434225bce3df050c220",
                  autorizacionPloterColaborador: "613a9434225bce3df050c220",
                  autorizacionPloterDisenoColaborador: "613a9434225bce3df050c220",
                  autorizacionSurtirColaborador: "613a9434225bce3df050c220",
                  descuento: 0,
                  sociedades: selectedSociedad,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(async (dataPedido) => {
                await axios
                  .post(
                    URL_PEDIDOS_CLIENTE,
                    {
                      fecha,
                      clientes: selectedCliente,
                      colaboradores: selectedColaborador,
                      fecha_cancelacion: hoy,
                      fecha_cita: hoy,
                      fecha_confirmacion: hoy,
                      observaciones,
                      total_general,
                      total_piezas,
                      pedidoCliente: "NA",
                      compradores: selectedComprador,
                      surtido: 0,
                      pendiente_surtir:0,
                      articulos: "65135444c2c4d12b8790d4e6",
                      pedidos: dataPedido.data._id,
                      status: "Abierto",
                      empresas: selectedEmpresa,
                      descuento:0,
                      sociedades: selectedSociedad,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((dataPedidoCliente) => {


                    inputFields.map((a) => {
                      if (a.articulos != "" && a.cantidad != 0) {
                        axios
                          .post(
                            URL_ARTICULOS_PEDIDO,
                            {
                              pedidos: dataPedido.data._id,
                              articulos: a.articulos,
                              colores: a.colores,
                              tallas: a.tallas,
                              cantidad: parseFloat(a.cantidad),
                              pendienteCortar: parseFloat(a.cantidad),
                              cortado: 0,
                              surtido: 0,
                              pendiente_surtir: parseFloat(a.cantidad),
                              cantidad_procesos: parseFloat(a.cantidad),
                              precio: parseFloat(a.precio),
                              descuento: 0,
                              total: (parseFloat(a.cantidad) * parseFloat(a.precio)).toFixed(2),
                              codigoBarras: 0,
                              precioEtiqueta: 0,
                              resurtido: "No",
                              codigoCliente: "NA",
                              sociedades: selectedSociedad,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then((dataArtPed) => {
                            axios
                              .post(
                                URL_ARTICULOS_PEDIDO_CLIENTE,
                                {
                                  pedidos: dataPedido.data._id,
                                  pedidosCliente: dataPedidoCliente.data._id,
                                  articulos: a.articulos,
                                  articulosPedido: dataArtPed.data._id,
                                  tallas: a.tallas,
                                  colores: a.colores,
                                  cantidad: parseFloat(a.cantidad),
                                  surtido: 0,
                                  pendiente_surtir: parseFloat(a.cantidad),
                                  precio: a.precio,
                                  descuento: 0,
                                  total: a.cantidad * a.precio,
                                  sociedades: selectedSociedad,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )

                              .then((data) => {
                                totalPedido = totalPedido - 1;
                                if (totalPedido == 0) {
                                  axios
                                    .post(
                                      URL_LOGS,
                                      {
                                        tipo: "Crear Pedido",
                                        detalle: `${value._id} ${total_general}`,
                                        user: user.id,
                                      },
                                      {
                                        headers: {
                                          Authorization: `Bearer: ${localStorage.getItem(
                                            "app_token"
                                          )}`,
                                        },
                                      }
                                    )
                                    .then(() => {
                                      Swal.fire(
                                        "Good job!",
                                        "Creado con exito",
                                        `success`
                                      );
                                      setTimeout(() => {
                                        window.location.reload();
                                      }, 1000);
                                    });
                                }
                              })
                              .catch((error) => {
                                Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: "Something went wrong!",
                                  // footer: `${error.response.data}`,
                                });
                                console.log(error);
                                setValidaBoton(true);
                              });
                          });
                      } else {
                        totalPedido = totalPedido - 1;
                        if (totalPedido == 0) {
                          axios
                            .post(
                              URL_LOGS,
                              {
                                tipo: "Crear Pedido",
                                detalle: `${value._id} ${total_general}`,
                                user: user.id,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer: ${localStorage.getItem(
                                    "app_token"
                                  )}`,
                                },
                              }
                            )
                            .then(() => {
                              Swal.fire(
                                "Good job!",
                                "Creado con exito",
                                `success`
                              );
                              setTimeout(() => {
                                window.location.reload();
                              }, 1000);
                            });
                        }
                      }
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          } catch (error) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Faltan datos, favor de revisar",
          });
        }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        tallas: "",
        colores: "",
        cantidad: 0,
        precio: 0,
        total: 0,
        articulosNombre: ""
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let cantidad = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        i.total = parseFloat(i.cantidad) * parseFloat(i.precio);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  // const handleChangeInputCantidad = (id, colores, tallas, event) => {
  //   const newInputFields = inputFields.map((i) => {
  //     if (id === i.id) {
  //       i[event.target.name] = event.target.value;

  //       let can = i.tc.map((c) => parseFloat(c.cantidad));
  //       let TC = can.reduce((t, total, index) => t + total, 0);

  //       i.total = parseFloat(i.cantidad) * parseFloat(i.precio);
  //     }
  //     return i;
  //   });
  //   setInputFields(newInputFields);

  //   ActualizaTotales();
  // };

  function BuscaArticulo(id, selectedArticulo) {
 
    articulos.map((a) => {
      if (a._id == selectedArticulo._id) {
        let idArticulo = selectedArticulo._id;
        let articulosNombre = selectedArticulo.codigo + " " + selectedArticulo.codigoCliente
        let precio = a.precio;
        handleChangeInputArticulo(id, idArticulo, precio, articulosNombre);
      }
    })
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    precio,
    articulosNombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.precio = precio;
        i.articulosNombre = articulosNombre
      }
      return i;
    });
    setInputFields(newInputFields);

    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  const options = articulos.map((option) => {
    const junta = option.codigo + " " + option.codigoCliente;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });



  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Venta Saldero</h3>
          <Form onSubmit={savePedido}>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Sociedad</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSociedad}
                  required
                  onChange={(e) => {
                    setSelectedSociedad(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Sociedad</option>
                  {sociedades
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Cliente</Label>
                <Input
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {clientes
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre_comercial}
                          </option>
                        );
                    })}
                </Input>
              </Col>

              <Col md={2}>
                <Label>Vendedor</Label>
                <Input
                  type="select"
                  value={selectedColaborador}
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                  }}
                >
                  <option value="">Selecciona un Vendedor</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Fecha Cancelacion</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cancelacion"
                  value={fecha_cancelacion}
                  required
                  onChange={(e) => {
                    setFechaCancelacion(e.target.value);
                    let inicio = new Date(e.target.value)
                    let bla = inicio.setDate(inicio.getDate()-45);
                    setFechaConfirmacion(moment(bla).format("YYYY-MM-DD"))
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Label>Fecha Confirmacion</Label>
                <Input
                  type="date"
                  placeholder="Fecha Confirmacion"
                  value={fecha_confirmacion}
                  required
                  disabled
                />
              </Col>
            <Col md={2}>
                    <Label className="mr-sm-2">Empresa</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedEmpresa}
                      required
                      onChange={(e) => {
                        setSelectedEmpresa(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {empresas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Comprador</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedComprador}
                      required
                      onChange={(e) => {
                        setSelectedComprador(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {compradores
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Pedido Cliente
                </Label>
                <Input
                  type="text"
                  placeholder="Pedido Cliente"
                  value={pedidoCliente}
                  required
                  onChange={(e) => {
                    setPedidoCliente(e.target.value);
                  }}
                />
              </Col> */}
            </Row>
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Selecciona</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Autocomplete
                size="small"
                value={inputField.articulosNombre}
                onChange={(event, selectedArticulo) => {
                  BuscaArticulo(inputField.id, selectedArticulo);
                }}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.junta}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona"
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.codigo} {option.codigoCliente}
                  </React.Fragment>
                )}
              />
              </Col>

              <Col md={2}>
               <Input
                      
                      name="articulo"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </Col>


                  <Col md={1}>
                    <Input
                      name="tallas"
                      className="col-sm-12"
                      type="select"
                      value={inputField.tallas}
                      required
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event)
                      }}
                    >
                      <option value="">Selecciona</option>
                      {tallas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      name="colores"
                      className="col-sm-12"
                      type="select"
                      value={inputField.colores}
                      required
                      onChange={(event) => {
                        handleChangeInput(inputField.id, event)
                      }}
                    >
                      <option value="">Selecciona</option>
                      {colores
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">
                  TOTALES {total_piezas} pzas. /{" "}
                  {"$" + new Intl.NumberFormat("en-US").format(total_general)}
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoPedidos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default VentaSalderos;
