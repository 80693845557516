import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function ListadoVentas() {
  const { user } = useContext(AuthContext);
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;

  const [surtidos, setSurtidos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_SURTIDOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido < b.idSurtido ? 1 : -1))
          .map((a) => {
            if(a.fecha >= "2023-04-24"){
            return {
              _id: a._id,
              is_active:a.is_active,
              fecha: a.fecha,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              idpedidos: a.pedidos[0].idPedido,
              pedidoCliente: a.pedidos[0].pedidoCliente,
              clientes: a.clientes[0].nombre_comercial,
              codigo: a.clientes[0].codigo,
              idClientes: a.clientes[0]._id,
              observaciones: a.observaciones,
              numero: a.idSurtido,
              impuestos: a.impuestos,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              RFC: a.clientes[0].RFC,
              vendedor: a.pedidos[0].colaboradores[0].nombre + " " + a.pedidos[0].colaboradores[0].apellido,
              plazo:a.clientes[0].plazo,
              recibida: a.recibida,
              numeroFactura: a.numeroFactura
            }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setSurtidos(allSurtidos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let totalImportePDF = 0;
  let subTotalPDF = 0;
  let totalIVAPDF = 0;

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        if(a.is_active == "Si"){
        totalImportePDF = totalImportePDF + a.total_general;
        subTotalPDF = subTotalPDF + a.subTotal;
        totalIVAPDF = totalIVAPDF + a.iva;
        return [
          a.numero,
          a.numeroFactura,
          a.fecha,
          a.clientes,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.subTotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.iva),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
          a.user,
        ]
      }else {
        return [
          a.numero,
          a.numeroFactura,
          a.fecha,
          a.clientes,
          "",
          "Cancelada",
          "",
          a.user,
        ]
      }
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["No.", "Factura", "Fecha", "Cliente", "SubTotal", "IVA", "Total", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotalPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalIVAPDF),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImportePDF),

        ]
      ]
    });
    doc.save(`Notas.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        if(a.is_active == "Si"){
        return {
          Numero: a.numero,
          Factura: a.numeroFactura,
          Fecha: a.fecha,
          Cliente: a.clientes,
          SubTotal: a.subTotal,
          IVA: a.iva,
          Total: a.total_general,
          Surtio: a.user,
        }
        }else{
          return {
            Numero: a.numero,
            Factura: a.numeroFactura,
            Fecha: a.fecha,
            Cliente: a.clientes,
            SubTotal: 0,
            IVA: 0,
            Total: 0,
            Surtio: a.user,
          }
        }
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: ["Numero", "Factura", "Fecha", "Cliente", "SubTotal", "IVA", "Total", "Surtio"],
        sheetHeader: ["Numero", "Factura", "Fecha", "Cliente", "SubTotal", "IVA", "Total", "Surtio"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [a.numero, a.numeroFactura, a.fecha, a.clientes, a.pedidos, a.subTotal, a.iva, a.total_general, a.user];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Factura", "Fecha", "Cliente", "Pedido", "SubTotal", "IVA", "Total", "Surtio"]],
      body: dataPDFLimpia,
      startY: 50,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function cancelarNota(idNota) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara la Nota!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_SURTIDOS}Cancel/${idNota}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    numero,
    idPDFOC,
    user,
    pedidos,
    impuestos,
    subTotal,
    iva,
    total_general,
    plazo,
    numeroFactura
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigoCliente + " " + a.articulos[0].descripcion,
              colores: a.colores[0].name,
              tallas: a.tallas[0].name,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              idColor: a.colores[0]._id,
              precio: a.articulosPedido[0].precio,
              descuento: a.articulosPedido[0].descuento,
              total: (parseFloat(a.cantidad) * parseFloat(a.articulosPedido[0].precio) - (parseFloat(a.cantidad) * parseFloat(a.articulosPedido[0].precio) * a.articulosPedido[0].descuento) / 100).toFixed(2),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.colores,
            a.tallas,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US").format(a.descuento) + "%",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF();
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 150, 10, 35, 10);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 80, 10, 30, 12);
        
        
        doc.setFontSize(10);
        // doc.text(`Domicilio: Calle: AV 16 de Septiembre No. 46 Bodega 2E y 2D, Col. Alve Blanco, CP: 53370,`, 20, 25);
        doc.text(`Naucalpan de juarez, Estado de México, México`, 20, 30);
        doc.setFontSize(10);
        
        doc.text(`Cliente ${clientes}`, 15, 40);
        doc.text(`Plazo ${plazo} días`, 15, 45);
        doc.text(`Faactura ${numeroFactura}`, 15, 50);

        doc.text(`Fecha ${fecha}`, 110, 40);
        doc.text(`Nota # ${numero}`, 110, 45);
        doc.text(`Forma de pago: Pago en una sola exhibición`, 110, 50);
        doc.text(`Método de pago y cuenta: PPD`, 110, 55);
       
        doc.autoTable({
          head: [["Articulo", "Color", "Talla", "Cantidad", "Precio", "Descuento", "Total"]],
          body: dataPDFLimpia,
          startY: 60,
          foot: [
            [
              "",
              "",
              "",
              "",
              "",
              "Sub Total",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotal),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "IVA",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(iva),
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);

        doc.save(`Nota-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  

  // async function Utilidad(
  //   fecha,
  //   idClientes,
  //   clientes,
  //   observaciones,
  //   numero,
  //   idPDFOC,
  //   user,
  //   pedidos,
  //   impuestos,
  //   subTotal,
  //   iva,
  //   total_general,
  //   plazo
  // ) {
  //   let totalCosto = 0;
  //   let totalVenta = 0;
  //   let totalUtilidad = 0;
  //   await axios
  //     .get(`${URL_INVENTARIOS}/surtidos/${idPDFOC}`, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       let allArticulosSurtido = response.data;
  //       let arrayTabla = allArticulosSurtido
  //         .map((a) => {
  //           return {
  //             id: a._id,
  //             articulos: a.articulos[0].codigo + " / " + a.articulos[0].codigoCliente + " " + a.articulos[0].descripcion,
  //             idArticulo: a.articulos[0]._id,
  //             colores: a.colores[0].name,
  //             idColores: a.colores[0]._id,
  //             cantidad: a.cantidad,
  //             precio: parseFloat(a.articulosPedido[0].precio) * a.cantidad,
  //             costo: parseFloat(a.telasOC[0].precio) * a.cantidad,
  //             utilidad: (parseFloat(a.articulosPedido[0].precio) - parseFloat(a.telasOC[0].precio) * a.cantidad) ,
  //           };
  //         })
  //         .filter(function (el) {
  //           return el != null;
  //         });

  //         let agrupado = arrayTabla.reduce(function (groups, item) {
  //           const val = item["idArticulo"] + item["idColores"]
  //       groups[val] = groups[val] || {
  //         idArticulo: item.idArticulo,
  //         idColores: item.idColores,
  //         costo: 0,
  //         precio: 0,
  //         utilidad: 0,
  //         cantidad: 0
  //       };
  //       groups[val].costo += item.costo;
  //       groups[val].precio += item.precio;
  //       groups[val].utilidad += item.utilidad;
  //       groups[val].cantidad += item.cantidad;
  //       groups[val].articulos = item.articulos;
  //       groups[val].idArticulo = item.idArticulo;
  //       groups[val].colores = item.colores;
  //       groups[val].idColores = item.idColores;
  //       return groups;
  //     },[])
  
  //     // let computedComments= Object.values(agrupado)

  //       let dataFinal = Object.values(agrupado);

  //       let mts = dataFinal.filter((e) => e.unidad == "Metros");
  //       let totalMts = mts.map((c) => parseFloat(c.cantidad));
  //       let TM = totalMts.reduce((t, total, index) => t + total, 0);
  //       setTotalMetrosEdit(TM);
  //       let KG = dataFinal.filter((e) => e.unidad == "Kilos");
  //       let totalKG = KG.map((c) => parseFloat(c.cantidad));
  //       let TK = totalKG.reduce((t, total, index) => t + total, 0);
  //       setTotalKilosEdit(TK);

  //       const data = dataFinal.map((a) => {
  //         totalCosto = totalCosto + a.costo;
  //         totalVenta = totalVenta + a.precio;
  //         return [
  //           a.articulos,
  //           a.colores,
  //           new Intl.NumberFormat("en-US").format(a.cantidad),
  //           new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
  //           new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costo),
  //           new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio - a.costo),
  //         ];
  //       });
  //       const dataPDF = Object.values(data);
  //       const dataPDFLimpia = dataPDF.filter(function (el) {
  //         return el != null;
  //       });
  //       const doc = new jsPDF();
  //       var img = new Image();
  //       img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  //       doc.addImage(img, "png", 150, 10, 35, 10);
  //       var img2 = new Image();
  //       img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  //       doc.addImage(img2, "png", 80, 10, 30, 12);
        
        
  //       doc.setFontSize(10);
  //       doc.text(`Domicilio: Calle: AV 16 de Septiembre No. 46 Bodega 2E y 2D, Col. Alve Blanco, CP: 53370,`, 20, 25);
  //       doc.text(`Naucalpan de juarez, Estado de México, México`, 20, 30);
  //       doc.setFontSize(10);
        
  //       doc.text(`Cliente ${clientes}`, 15, 40);
  //       doc.text(`Plazo ${plazo} días`, 15, 45);
  //       doc.text(
  //         `Metros ${new Intl.NumberFormat("en-US").format(TM)}`,
  //         15,
  //        50
  //       );

  //       doc.text(`Fecha ${fecha}`, 110, 40);
  //       doc.text(`Nota # ${numero}`, 110, 45);
  //       doc.text(`Forma de pago: Pago en una sola exhibición`, 110, 50);
  //       doc.text(`Método de pago y cuenta: PPD`, 110, 55);
       
  //       doc.autoTable({
  //         head: [
  //           [
  //             "Articulo",
  //             "Color",
  //             "Cantidad",
  //             "Venta",
  //             "Costo",
  //             "Utilidad",
  //           ],
  //         ],
  //         body: dataPDFLimpia,
  //         startY: 60,
  //         foot: [
  //           [
  //             "",
  //             "",
  //             "Totales",
  //             new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalVenta),
  //             new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalCosto),
  //             new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalVenta - totalCosto),
  //           ],
  //         ],
  //         showFoot: "lastPage",
  //       });

  //       doc.setFontSize(10);
  //       var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
  //       doc.text(10, doc.autoTable.previous.finalY + 10, lines);

  //       doc.save(`Nota-${numero}.pdf`);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // async function recibida(id){
  //   await axios
  //         .patch(
  //           `${URL_SURTIDOS}/${id}`,
  //           {
  //             recibida: "Si",
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //             },
  //           }
  //         )
  //         .then(() => {
  //           // toggle();
  //           Swal.fire("Good job!", "Actualizado con exito", "success");
  //           const newComments = comments.map((i) => {
  //             if (id === i._id) {
  //               i.recibida = "Si";
  //             }
  //             return i;
  //           });
  //           setComments(newComments);
  //         })
  //          .catch((error) => {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: "Something went wrong!",
  //             footer: `${error.response.data}`,
  //           });
  //           console.log(error);
  //         });
  // }
  console.table(comments)

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Factura", field: "numeroFactura", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "SubTotal", field: "subTotal", sortable: true },
    { name: "IVA", field: "iva", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroFactura.toLowerCase().includes(search.toLowerCase()) ||
          // comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search) ||
          comment.subTotal.toString().includes(search) ||
          comment.iva.toString().includes(search) ||
          comment.total_general.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero" &&
      sorting.field != "total_general" &&
      sorting.field != "subTotal" &&
      sorting.field != "iva"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" ||
        sorting.field == "numero" ||
        sorting.field == "total_general" ||
        sorting.field == "subTotal" ||
        sorting.field == "iva")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" ||
        sorting.field == "numero" ||
        sorting.field == "total_general" ||
        sorting.field == "subTotal" ||
        sorting.field == "iva")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  async function validarSurtido(id){
    await axios
          .patch(
            `${URL_SURTIDOS}/${id}`,
            {
              recibida: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            // toggle();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            const newComments = comments.map((i) => {
              if (id === i._id) {
                i.recibida = "Si";
              }
              return i;
            });
            setComments(newComments);
          })
           .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });

  }

  let totalImporteTabla = 0;
  let subTotalTabla = 0;
  let totalIVATabla = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Notas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ventas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Clientes</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) =>
                    a.nombre_comercial > b.nombre_comercial ? 1 : -1
                  )
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                if(a.is_active == "No"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c"}}>
                      <td>{a.numero}</td>
                      <td>{a.numeroFactura}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.vendedor}</td>
                      <td>{a.is_active == "Si" ? (
                        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general)
                          ) : undefined}
                      </td>
                      <td>CANCELADA</td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }else{
                  totalImporteTabla = totalImporteTabla + a.total_general;
                  subTotalTabla = subTotalTabla + a.subTotal;
                  totalIVATabla = totalIVATabla + a.iva;
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.numeroFactura}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.vendedor}</td>
                      <td>{ new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.subTotal)}
                      </td>
                      <td>{ new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.iva)}
                      </td>
                      <td>{ new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general)}
                      </td>
                      <td>
                          <>
                          {a.recibida == "No" ? (
                        <>
                        <Button
                            size="sm"
                            className="btn"
                            color="success"
                            onClick={(e) =>validarSurtido(a._id)}
                          >
                            <i class="far fa-check-circle"></i>
                          </Button>
                        </>
                      ) : undefined}
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.plazo,
                                  a.numeroFactura
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) => cancelarNota(a._id)}
                            >
                              <i class="fas fa-ban"></i>
                            </Button>
                            {/* <Button
                              size="sm"
                              className="btn"
                              color="primary"
                              onClick={(e) =>
                                Utilidad(
                                  a.fecha,
                                  a.idClientes,
                                  a.clientes,
                                  a.observaciones,
                                  a.numero,
                                  a._id,
                                  a.user,
                                  a.pedidos,
                                  a.impuestos,
                                  a.subTotal,
                                  a.iva,
                                  a.total_general,
                                  a.plazo
                                )
                              }
                            >
                            <i class="fas fa-chart-line"></i>
                            </Button> */}
                            {/* {a.recibida == "No" ? (
                              <Button
                              size="sm"
                              className="btn"
                              color="success"
                              onClick={(e) => recibida(a._id)}
                            >
                              <i class="far fa-check-circle"></i>
                            </Button>
  
                            ):undefined} */}
                          </>
                      </td>
                    </tr>
                  );
                }

              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotalTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalIVATabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalImporteTabla)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoVentas;
