import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";

import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPedidosDiseno() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;

  let hoy = new Date();
  let inicio = new Date();
  let inicioCanc = new Date();
  inicio.setDate(inicio.getDate() - 60);
  inicioCanc.setDate(inicio.getDate() + 360);

  let valida = new Date();
  valida.setDate(valida.getDate() + 8);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");
  let endDateCanc = moment(inicioCanc).format("YYYY-MM-DD");
  let validador = moment(valida).format("YYYY-MM-DD");

  const [pedidos, setPedidos] = useState([]);

  const [articulos, setArticulos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedStatusTela, setSelectedStatusTela] = useState("");
  const [selectedResurtido, setSelectedResurtido] = useState("");
  const [selectedAutorizacionDiseno, setSelectedAutorizacionDiseno] = useState("");
  const [selectedAutorizacionFit, setSelectedAutorizacionFit] = useState("");
  const [selectedAutorizacionCalidad, setSelectedAutorizacionCalidad] = useState("");
  const [selectedAutorizacionPloter, setSelectedAutorizacionPloter] = useState("");
  const [selectedAutorizacionPloterDiseno, setSelectedAutorizacionPloterDiseno] = useState("");
  const [selectedAutorizacionCostos, setSelectedAutorizacionCostos] = useState("");
  const [selectedAutorizacionSurtir, setSelectedAutorizacionSurtir] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");

  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [selectedFechaConfInicio, setselectedFechaConfInicio] = useState(startDate);
  const [selectedFechaConfFin, setselectedFechaConfFin] = useState(endDateCanc);
  const [selectedPatronistaFiltro, setSelectedPatronistaFiltro] = useState("");
  const [selectedMuestristaFiltro, setSelectedMuestristaFiltro] = useState("");
  const [selectedPloterFiltro, setSelectedPloterFiltro] = useState("");

  const [fecha, setFecha] = useState("");
  const [fecha_cancelacion, setFechaCancelacion] = useState("");
  const [fecha_confirmacion, setFechaConfirmacion] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState("");
  const [total_kilos, setTotalUnidaKilos] = useState("");
  const [total_metros, setTotalUnidaMetros] = useState("");
  const [numero_pedido, setNumeroPedido] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");
  const [selectedPatronistaEdit, setSelectedPatronistaEdit] = useState("");
  const [selectedMuestristaEdit, setSelectedMuestristaEdit] = useState("");
  const [selectedPloterEdit, setSelectedPloterEdit] = useState("");
  const [idArticuloEdit, setIdArticuloEdit] = useState("");
  const [costoEdit, setCostoEdit] = useState(0);

  const [articulosPedido, setArticulosPedido] = useState([]);
  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [fileImagenes, setFileImagenes] = useState();

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);
  const [totalTotalCantidad, setTotalTotalCantidad] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      resurtido: "",
      unidad: 0,
      colores: "",
      arrayColoresArticulo: [],
      tallas: "",
      arrayTallasArticulo: [],
      cantidad: 0,
      surtido: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      codigoBarras: 0,
    },
  ]);

  const [explosion, setExplosion] = useState([]);
  const [explosionHab, setExplosionHab] = useState([]);
  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");
  const [selectedActivo, setSelectedActivo] = useState("Si");

  const [statusPedido, setStatusPedido] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [statusNumero, setStatusNumero] = useState("");
  const [statusCodigos, setStatusCodigos] = useState("");
  const [statusFicha, setStatusFicha] = useState("");
  const [statusAuditoria, setStatusAuditoria] = useState("");
  const [statusMuestraFit, setStatusMuestraFit] = useState("");
  const [statusMuestraConfirmacion, setStatusMuestraConfirmacion] =
    useState("");
  const [statusEtiquetas, setStatusEtiquetas] = useState("");

  const [fechaEstCodigos, setFechaEstCodigos] = useState("");
  const [fechaRealCodigos, setFechaRealCodigos] = useState("");
  const [fechaEstFicha, setFechaEstFicha] = useState("");
  const [fechaRealFicha, setFechaRealFicha] = useState("");
  const [fechaEstAuditoria, setFechaEstAuditoria] = useState("");
  const [fechaRealAuditoria, setFechaRealAuditoria] = useState("");
  const [fechaEstMuestraFit, setFechaEstMuestraFit] = useState("");
  const [fechaRealMuestraFit, setFechaRealMuestraFit] = useState("");
  const [fechaEstMuestraConfirmacion, setFechaEstMuestraConfirmacion] =
    useState("");
  const [fechaRealMuestraConfirmacion, setFechaRealMuestraConfirmacion] =
    useState("");
  const [fechaEstEtiquetas, setFechaEstEtiquetas] = useState("");
  const [fechaRealEtiquetas, setFechaRealEtiquetas] = useState("");

  const [modalStatus, setModalStatus] = useState(false);
  const toggleStatus = () => setModalStatus(!modalStatus);

  const [pedidoAutorizaciones, setPedidoAutorizaciones] = useState([]);
  const [modalAutorizaciones, setModalAutorizaciones] = useState(false);
  const toggleAutorizaciones = () =>
    setModalAutorizaciones(!modalAutorizaciones);
  const [autorizacionId, setAutorizacionId] = useState("");
  const [autorizacionCostos, setAutorizacionCostos] = useState("");
  const [autorizacionDiseno, setAutorizacionDiseno] = useState("");
  const [autorizacionCalidad, setAutorizacionCalidad] = useState("");
  const [autorizacionPloter, setAutorizacionPloter] = useState("");
  const [autorizacionPloterDiseno, setAutorizacionPloterDiseno] = useState("");
  const [autorizacionFit, setAutorizacionFit] = useState("");
  const [autorizacionSurtir, setAutorizacionSurtir] = useState("");

  const [autorizacionCostosColaborador, setAutorizacionCostosColaborador] =
    useState("");
  const [autorizacionDisenoColaborador, setAutorizacionDisenoColaborador] =
    useState("");
  const [autorizacionCalidadColaborador, setAutorizacionCalidadColaborador] =
    useState("");
  const [autorizacionPloterColaborador, setAutorizacionPloterColaborador] =
    useState("");
  const [
    autorizacionPloterDisenoColaborador,
    setAutorizacionPloterDisenoColaborador,
  ] = useState("");
  const [autorizacionFitColaborador, setAutorizacionFitColaborador] =
    useState("");
  const [autorizacionSurtirColaborador, setAutorizacionSurtirColaborador] =
    useState("");

  const [autorizacionCostosFecha, setAutorizacionCostosFecha] = useState("");
  const [autorizacionDisenoFecha, setAutorizacionDisenoFecha] = useState("");
  const [autorizacionCalidadFecha, setAutorizacionCalidadFecha] = useState("");
  const [autorizacionPloterFecha, setAutorizacionPloterFecha] = useState("");
  const [autorizacionPloterDisenoFecha, setAutorizacionPloterDisenoFecha] =
    useState("");
  const [autorizacionFitFecha, setAutorizacionFitFecha] = useState("");
  const [autorizacionSurtirFecha, setAutorizacionSurtirFecha] = useState("");

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [idEditArticulo, setIdEditArticulo] = useState("");

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [modalFichaTecnica, setModalFichaTecnica] = useState(false);
  const toggleFichaTecnica = () => setModalFichaTecnica(!modalFichaTecnica);

  const [modalFotoPedido, setModalFotoPedido] = useState(false);
  const toggleFotoPedido = () => setModalFotoPedido(!modalFotoPedido);

  const [photoImagenes, setPhotoImagenes] = useState([]);
  const [modalVerFotoImagenes, setModalVerFotoImagenes] = useState(false);
  const toggleVerFotoImagenes = () => setModalVerFotoImagenes(!modalVerFotoImagenes);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(`${URL_PEDIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.idPedido > b.idPedido ? 1 : -1))
          .map((a) => {
            if (a.status == "Abierto") {
              return {
                _id: a._id,
                numero: a.idPedido,
                activo: a.is_active,
                fecha: a.fecha,
                articulos:
                  "Cod: " +
                  a.articulos[0].codigoCliente +
                  " / Ref: " +
                  a.articulos[0].codigo +
                  " / Desc: " +
                  a.articulos[0].descripcion,
                idArticulo: a.articulos[0]._id,
                patronista:
                  a.articulos[0].patronista[0].nombre +
                  " " +
                  a.articulos[0].patronista[0].apellido,
                idPatronista: a.articulos[0].patronista[0]._id,
                muestrista:
                  a.articulos[0].muestrista[0].nombre +
                  " " +
                  a.articulos[0].muestrista[0].apellido,
                idMuestrista: a.articulos[0].muestrista[0]._id,
                ploter:
                  a.articulos[0].ploter[0].nombre +
                  " " +
                  a.articulos[0].ploter[0].apellido,
                idPloter: a.articulos[0].ploter[0]._id,
                colaboradores:
                  a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                idColaboradores: a.colaboradores[0]._id,
                fecha_cancelacion: a.fecha_cancelacion,
                fecha_confirmacion: a.fecha_confirmacion,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_kilos: a.total_kilos,
                total_metros: a.total_metros,
                status: a.status,
                autorizado: a.autorizado,
                cantidad: parseFloat(a.total_piezas),
                surtido: parseFloat(a.surtido),
                pendiente_surtir: parseFloat(a.pendiente_surtir),

                comprador: a.compradores[0].name,
                idComprador: a.compradores[0]._id,
                departamento: a.articulos[0].departamentos[0].name,
                idDepartamento: a.articulos[0].departamentos[0]._id,
                subDepartamento: a.articulos[0].subDepartamentos[0].name,
                idSubDepartamento: a.articulos[0].subDepartamentos[0]._id,
                clase: a.articulos[0].clases[0].name,
                idClase: a.articulos[0].clases[0]._id,

                codigos: a.codigos,
                ficha: a.ficha,
                auditoria: a.auditoria,
                muestraFit: a.muestraFit,
                muestraConfirmacion: a.muestraConfirmacion,
                etiquetas: a.etiquetas,
                fechaEstCodigos: a.fechaEstCodigos,
                fechaRealCodigos: a.fechaRealCodigos,
                fechaEstFicha: a.fechaEstFicha,
                fechaRealFicha: a.fechaRealFicha,
                fechaEstAuditoria: a.fechaEstAuditoria,
                fechaRealAuditoria: a.fechaRealAuditoria,
                fechaEstMuestraFit: a.fechaEstMuestraFit,
                fechaRealMuestraFit: a.fechaRealMuestraFit,
                fechaEstMuestraConfirmacion: a.fechaEstMuestraConfirmacion,
                fechaRealMuestraConfirmacion: a.fechaRealMuestraConfirmacion,
                fechaEstEtiquetas: a.fechaEstEtiquetas,
                fechaRealEtiquetas: a.fechaRealEtiquetas,

                autorizacionCostos: a.autorizacionCostos,
                autorizacionFit: a.autorizacionFit,
                autorizacionDiseno: a.autorizacionDiseno,
                autorizacionCalidad: a.autorizacionCalidad,
                autorizacionPloterDiseno: a.autorizacionPloterDiseno,
                autorizacionPloter: a.autorizacionPloter,
                autorizacionSurtir: a.autorizacionSurtir,

                autorizacionCostosFecha: a.autorizacionCostosFecha,
                autorizacionFitFecha: a.autorizacionFitFecha,
                autorizacionDisenoFecha: a.autorizacionDisenoFecha,
                autorizacionCalidadFecha: a.autorizacionCalidadFecha,
                autorizacionPloterDisenoFecha: a.autorizacionPloterDisenoFecha,
                autorizacionPloterFecha: a.autorizacionPloterFecha,
                autorizacionSurtirFecha: a.autorizacionSurtirFecha,

                autorizacionCostosColaborador:
                  a.autorizacionCostosColaborador[0].nombre +
                  " " +
                  a.autorizacionCostosColaborador[0].apellido,
                autorizacionFitColaborador:
                  a.autorizacionFitColaborador[0].nombre +
                  " " +
                  a.autorizacionFitColaborador[0].apellido,
                autorizacionDisenoColaborador:
                  a.autorizacionDisenoColaborador[0].nombre +
                  " " +
                  a.autorizacionDisenoColaborador[0].apellido,
                autorizacionCalidadColaborador:
                  a.autorizacionCalidadColaborador[0].nombre +
                  " " +
                  a.autorizacionCalidadColaborador[0].apellido,
                autorizacionPloterDisenoColaborador:
                  a.autorizacionPloterDisenoColaborador[0].nombre +
                  " " +
                  a.autorizacionPloterDisenoColaborador[0].apellido,
                autorizacionPloterColaborador:
                  a.autorizacionPloterColaborador[0].nombre +
                  " " +
                  a.autorizacionPloterColaborador[0].apellido,
                autorizacionSurtirColaborador:
                  a.autorizacionSurtirColaborador[0].nombre +
                  " " +
                  a.autorizacionSurtirColaborador[0].apellido,

                statusTela: a.statusTela,
                statusHabilitacion: a.statusHabilitacion,

                fotoArticulo: a.articulos[0].fotoArticulo,
                fotoDiseno: a.articulos[0].fotoDiseno,

                resurtido: a.resurtido,
                costo: a.articulos[0].costo
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["_id"];
          groups[val] = groups[val] || {
            _id: item._id,
            surtido: 0,
            pendiente_surtir: 0,
            cantidad: 0,
          };
          groups[val].surtido += item.surtido;
          groups[val].pendiente_surtir += item.pendiente_surtir;
          groups[val].cantidad += item.cantidad;
          groups[val]._id = item._id;
          groups[val].numero = item.numero;
          groups[val].activo = item.activo;
          groups[val].fecha = item.fecha;
          groups[val].articulos = item.articulos;
          groups[val].idArticulo = item.idArticulo;
          groups[val].patronista = item.patronista;
          groups[val].idPatronista = item.idPatronista;
          groups[val].muestrista = item.muestrista;
          groups[val].idMuestrista = item.idMuestrista;
          groups[val].ploter = item.ploter;
          groups[val].idPloter = item.idPloter;
          groups[val].colaboradores = item.colaboradores;
          groups[val].idColaboradores = item.idColaboradores;
          groups[val].fecha_cancelacion = item.fecha_cancelacion;
          groups[val].fecha_confirmacion = item.fecha_confirmacion;
          groups[val].total_general = item.total_general;
          groups[val].clientes = item.clientes;
          groups[val].idClientes = item.idClientes;
          groups[val].status = item.status;
          groups[val].autorizado = item.autorizado;
          groups[val].observaciones = item.observaciones;

          groups[val].comprador = item.comprador;
          groups[val].idComprador = item.idComprador;
          groups[val].departamento = item.departamento;
          groups[val].idDepartamento = item.idDepartamento;
          groups[val].subDepartamento = item.subDepartamento;
          groups[val].idSubdepartamento = item.idSubdepartamento;
          groups[val].clase = item.clase;
          groups[val].idClase = item.idClase;

          groups[val].codigos = item.codigos;
          groups[val].ficha = item.ficha;
          groups[val].auditoria = item.auditoria;
          groups[val].muestraFit = item.muestraFit;
          groups[val].muestraConfirmacion = item.muestraConfirmacion;
          groups[val].etiquetas = item.etiquetas;
          groups[val].fechaEstCodigos = item.fechaEstCodigos;
          groups[val].fechaRealCodigos = item.fechaRealCodigos;
          groups[val].fechaEstFicha = item.fechaEstFicha;
          groups[val].fechaRealFicha = item.fechaRealFicha;
          groups[val].fechaEstAuditoria = item.fechaEstAuditoria;
          groups[val].fechaRealAuditoria = item.fechaRealAuditoria;
          groups[val].fechaEstMuestraFit = item.fechaEstMuestraFit;
          groups[val].fechaRealMuestraFit = item.fechaRealMuestraFit;
          groups[val].fechaEstMuestraConfirmacion =
            item.fechaEstMuestraConfirmacion;
          groups[val].fechaRealMuestraConfirmacion =
            item.fechaRealMuestraConfirmacion;
          groups[val].fechaEstEtiquetas = item.fechaEstEtiquetas;
          groups[val].fechaRealEtiquetas = item.fechaRealEtiquetas;
          groups[val].autorizacionCostos = item.autorizacionCostos;
          groups[val].autorizacionFit = item.autorizacionFit;
          groups[val].autorizacionDiseno = item.autorizacionDiseno;
          groups[val].autorizacionCalidad = item.autorizacionCalidad;
          groups[val].autorizacionPloter = item.autorizacionPloter;
          groups[val].autorizacionPloterDiseno = item.autorizacionPloterDiseno;
          groups[val].autorizacionSurtir = item.autorizacionSurtir;

          groups[val].autorizacionCostosFecha = item.autorizacionCostosFecha;
          groups[val].autorizacionFitFecha = item.autorizacionFitFecha;
          groups[val].autorizacionDisenoFecha = item.autorizacionDisenoFecha;
          groups[val].autorizacionCalidadFecha = item.autorizacionCalidadFecha;
          groups[val].autorizacionPloterFecha = item.autorizacionPloterFecha;
          groups[val].autorizacionPloterDisenoFecha =
            item.autorizacionPloterDisenoFecha;
          groups[val].autorizacionSurtirFecha = item.autorizacionSurtirFecha;

          groups[val].autorizacionCostosColaborador =
            item.autorizacionCostosColaborador;
          groups[val].autorizacionFitColaborador =
            item.autorizacionFitColaborador;
          groups[val].autorizacionDisenoColaborador =
            item.autorizacionDisenoColaborador;
          groups[val].autorizacionCalidadColaborador =
            item.autorizacionCalidadColaborador;
          groups[val].autorizacionPloterColaborador =
            item.autorizacionPloterColaborador;
          groups[val].autorizacionPloterDisenoColaborador =
            item.autorizacionPloterDisenoColaborador;
          groups[val].autorizacionSurtirColaborador =
            item.autorizacionSurtirColaborador;

          groups[val].statusTela = item.statusTela;
          groups[val].statusHabilitacion = item.statusHabilitacion;

          groups[val].fotoArticulo = item.fotoArticulo;
          groups[val].fotoDiseno = item.fotoDiseno;
          groups[val].resurtido = item.resurtido;
          groups[val].costo = item.costo;

          return groups;
        }, []);

        let dataFinal = Object.values(agrupado);
        setPedidos(dataFinal);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedActivo, selectedFechaInicio, selectedFechaFin]);

  const ListaPedidos = comments.sort((a, b) =>
    a.idPedido > b.idPedido ? 1 : -1
  );

  function PDFTabla() {
      let computedComments = comments;
      if (search) {
        computedComments = computedComments.filter(
          (comment) =>
            // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
            comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
            comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
            comment.articulos.toLowerCase().includes(search.toLowerCase()) ||
            comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
            comment.fecha_cancelacion
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            comment.fecha_confirmacion
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            comment.patronista.toLowerCase().includes(search.toLowerCase()) ||
            comment.muestrista.toLowerCase().includes(search.toLowerCase()) ||
            comment.ploter.toLowerCase().includes(search.toLowerCase()) ||
            comment.statusTela.toLowerCase().includes(search.toLowerCase()) ||
            comment.comprador.toLowerCase().includes(search.toLowerCase()) ||
            comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
            comment.subDepartamento
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            comment.clase.toLowerCase().includes(search.toLowerCase()) ||
            comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
            comment.cantidad.toString().includes(search) ||
            comment.surtido.toString().includes(search) ||
            comment.pendiente_surtir.toString().includes(search) ||
            comment.total_general.toString().includes(search) ||
            comment.cantidad.toString().includes(search) ||
            comment.numero.toString().includes(search)
        );
      }
      if (selectedCliente) {
        computedComments = computedComments.filter((e) =>
          e.idClientes.includes(selectedCliente)
        );
      }
      if (selectedColaborador) {
        computedComments = computedComments.filter((e) =>
          e.idColaboradores.includes(selectedColaborador)
        );
      }

    if (selectedPatronistaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idPatronista.includes(selectedPatronistaFiltro)
      );
    }

    if (selectedMuestristaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idMuestrista.includes(selectedMuestristaFiltro)
      );
    }

    if (selectedPloterFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idPloter.includes(selectedPloterFiltro)
      );
    }
  
      if (selectedArticulo) {
        computedComments = computedComments.filter((e) =>
          e.idArticulo.includes(selectedArticulo)
        );
      }
      // if (activo) {
      //   computedComments = computedComments.filter((e) =>
      //     e.activo.includes(activo)
      //   );
      // }
  
      if (selectedFechaInicio) {
        computedComments = computedComments.filter(
          (e) => e.fecha >= selectedFechaInicio
        );
      }
  
      if (selectedFechaFin) {
        computedComments = computedComments.filter(
          (e) => e.fecha <= selectedFechaFin
        );
      }
  
      if (selectedFechaConfInicio) {
        computedComments = computedComments.filter(
          (e) => e.fecha_confirmacion >= selectedFechaConfInicio
        );
      }
  
      if (selectedFechaConfFin) {
        computedComments = computedComments.filter(
          (e) => e.fecha_confirmacion <= selectedFechaConfFin
        );
      }
  
      if (selectedStatusTela) {
        computedComments = computedComments.filter(
          (e) => e.statusTela.includes(selectedStatusTela)
        );
      }
      if (selectedResurtido) {
        computedComments = computedComments.filter(
          (e) => e.resurtido.includes(selectedResurtido)
        );
      }
      if (selectedAutorizacionDiseno) {
        computedComments = computedComments.filter(
          (e) => e.autorizacionDiseno.includes(selectedAutorizacionDiseno)
        );
      }
      if (selectedAutorizacionFit) {
        computedComments = computedComments.filter(
          (e) => e.autorizacionFit.includes(selectedAutorizacionFit)
        );
      }
      if (selectedAutorizacionCalidad) {
        computedComments = computedComments.filter(
          (e) => e.autorizacionCalidad.includes(selectedAutorizacionCalidad)
        );
      }
      if (selectedAutorizacionPloter) {
        computedComments = computedComments.filter(
          (e) => e.autorizacionPloter.includes(selectedAutorizacionPloter)
        );
      }
      if (selectedAutorizacionPloterDiseno) {
        computedComments = computedComments.filter(
          (e) => e.autorizacionPloterDiseno.includes(selectedAutorizacionPloterDiseno)
        );
      }
      if (selectedAutorizacionCostos) {
        computedComments = computedComments.filter(
          (e) => e.autorizacionCostos.includes(selectedAutorizacionCostos)
        );
      }
      if (selectedAutorizacionSurtir) {
        computedComments = computedComments.filter(
          (e) => e.autorizacionSurtir.includes(selectedAutorizacionSurtir)
        );
      }
  
      setTotalItems(computedComments.length);
  
      //Sorting comments
      if (
        sorting.field &&
        sorting.field != "cantidad" &&
        sorting.field != "surtido" &&
        sorting.field != "pendiente_surtir" &&
        sorting.field != "total_general" &&
        sorting.field != "numero"
      ) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
          (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
      }
  
      if (
        sorting.field &&
        sorting.order === "asc" &&
        (sorting.field == "cantidad" ||
          sorting.field == "surtido" ||
          sorting.field == "pendiente_surtir" ||
          sorting.field == "total_general" ||
          sorting.field == "numero")
      ) {
        computedComments = computedComments.sort((a, b) =>
          parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
        );
      }
  
      if (
        sorting.field &&
        sorting.order === "desc" &&
        (sorting.field == "cantidad" ||
          sorting.field == "surtido" ||
          sorting.field == "pendiente_surtir" ||
          sorting.field == "total_general" ||
          sorting.field == "numero")
      ) {
        computedComments = computedComments.sort((a, b) =>
          parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
        );
      }

    const data = computedComments.map((a) => {
      {
        return [
          a.numero,
          a.resurtido,
          a.fecha,
          a.clientes,
          // a.colaboradores,
          a.articulos,
          a.cantidad,
          // a.fecha_cancelacion,
          // a.fecha_confirmacion,
          a.comprador,
          // a.departamento,
          // a.subDepartamento,
          // a.clase,
          // a.observaciones,
          a.patronista,
          a.muestrista,
          a.ploter,
          // a.statusTela
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Resurtido",
          "Fecha",
          "Cliente",
          // "Vendedor",
          "Articulo",
          "Cantidad",
          // "Fecha Canc.",
          // "Fecha Conf.",
          "Copmrador",
          // "Departamento",
          // "SubDepartamento",
          // "Clase",
          // "Observaciones",
          "Patronista",
          "Muestrista",
          "Ploter",
          // "Status Tela",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(totalTotalCantidad),
        ],
      ],
      showFoot: "lastPage",
    });
    // doc.save(`Pedidos.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    let computedComments = comments;
    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulos.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha_cancelacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.fecha_confirmacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.patronista.toLowerCase().includes(search.toLowerCase()) ||
          comment.muestrista.toLowerCase().includes(search.toLowerCase()) ||
          comment.ploter.toLowerCase().includes(search.toLowerCase()) ||
          comment.statusTela.toLowerCase().includes(search.toLowerCase()) ||
          comment.comprador.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.subDepartamento
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.clase.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.surtido.toString().includes(search) ||
          comment.pendiente_surtir.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    // if (activo) {
    //   computedComments = computedComments.filter((e) =>
    //     e.activo.includes(activo)
    //   );
    // }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFechaConfInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha_confirmacion >= selectedFechaConfInicio
      );
    }

    if (selectedFechaConfFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha_confirmacion <= selectedFechaConfFin
      );
    }

    if (selectedStatusTela) {
      computedComments = computedComments.filter(
        (e) => e.statusTela.includes(selectedStatusTela)
      );
    }
    if (selectedResurtido) {
      computedComments = computedComments.filter(
        (e) => e.resurtido.includes(selectedResurtido)
      );
    }
    if (selectedAutorizacionDiseno) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionDiseno.includes(selectedAutorizacionDiseno)
      );
    }
    if (selectedAutorizacionFit) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionFit.includes(selectedAutorizacionFit)
      );
    }
    if (selectedAutorizacionCalidad) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionCalidad.includes(selectedAutorizacionCalidad)
      );
    }
    if (selectedAutorizacionPloter) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionPloter.includes(selectedAutorizacionPloter)
      );
    }
    if (selectedAutorizacionPloterDiseno) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionPloterDiseno.includes(selectedAutorizacionPloterDiseno)
      );
    }
    if (selectedAutorizacionCostos) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionCostos.includes(selectedAutorizacionCostos)
      );
    }
    if (selectedAutorizacionSurtir) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionSurtir.includes(selectedAutorizacionSurtir)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "surtido" &&
      sorting.field != "pendiente_surtir" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }


    const dataExcel = computedComments.map((a) => {
        return {
          numero: a.numero,
          resurtido: a.resurtido,
          fecha: a.fecha,
          clientes: a.clientes,
          colaboradores: a.colaboradores,
          articulo: a.articulo,
          cantidad: a.cantidad,
          fecha_cancelacion: a.fecha_cancelacion,
          fecha_confirmacion: a.fecha_confirmacion,
          comprador: a.comprador,
          departamento: a.departamento,
          subDepartamento: a.subDepartamento,
          clase: a.clase,
          observaciones: a.observaciones,
          patronista: a.patronista,
          muestrista: a.muestrista,
          ploter: a.ploter,
          statusTela: a.statusTela,
          autorizacionDiseno: a.autorizacionDiseno,
          autorizacionFit: a.autorizacionFit,
          autorizacionCalidad: a.autorizacionCalidad,
          autorizacionPloter: a.autorizacionPloter,
          autorizacionPloterDiseno: a.autorizacionPloterDiseno,
          autorizacionCostos: a.autorizacionCostos,
          autorizacionSurtir: a.autorizacionSurtir,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "numero",
          "resurtido",
          "fecha",
          "clientes",
          "colaboradores",
          "articulo",
          "cantidad",
          "fecha_cancelacion",
          "fecha_confirmacion",
          "comprador",
          "departamento",
          "subDepartamento",
          "clase",
          "observaciones",
          "patronista",
          "muestrista",
          "ploter",
          "statusTela",
          "autorizacionDiseno",
          "autorizacionFit",
          "autorizacionCalidad",
          "autorizacionPloter",
          "autorizacionPloterDiseno",
          "autorizacionCostos",
          "autorizacionSurtir",
        ],
        sheetHeader: [
          "numero",
          "resurtido",
          "fecha",
          "clientes",
          "colaboradores",
          "articulo",
          "cantidad",
          "fecha_cancelacion",
          "fecha_confirmacion",
          "comprador",
          "departamento",
          "subDepartamento",
          "clase",
          "observaciones",
          "patronista",
          "muestrista",
          "ploter",
          "statusTela",
          "autorizacionDiseno",
          "autorizacionFit",
          "autorizacionCalidad",
          "autorizacionPloter",
          "autorizacionPloterDiseno",
          "autorizacionCostos",
          "autorizacionSurtir",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let computedComments = comments;
    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulos.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha_cancelacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.fecha_confirmacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.patronista.toLowerCase().includes(search.toLowerCase()) ||
          comment.muestrista.toLowerCase().includes(search.toLowerCase()) ||
          comment.ploter.toLowerCase().includes(search.toLowerCase()) ||
          comment.statusTela.toLowerCase().includes(search.toLowerCase()) ||
          comment.comprador.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.subDepartamento
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.clase.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.surtido.toString().includes(search) ||
          comment.pendiente_surtir.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    // if (activo) {
    //   computedComments = computedComments.filter((e) =>
    //     e.activo.includes(activo)
    //   );
    // }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFechaConfInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha_confirmacion >= selectedFechaConfInicio
      );
    }

    if (selectedFechaConfFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha_confirmacion <= selectedFechaConfFin
      );
    }

    if (selectedStatusTela) {
      computedComments = computedComments.filter(
        (e) => e.statusTela.includes(selectedStatusTela)
      );
    }
    if (selectedResurtido) {
      computedComments = computedComments.filter(
        (e) => e.resurtido.includes(selectedResurtido)
      );
    }
    if (selectedAutorizacionDiseno) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionDiseno.includes(selectedAutorizacionDiseno)
      );
    }
    if (selectedAutorizacionFit) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionFit.includes(selectedAutorizacionFit)
      );
    }
    if (selectedAutorizacionCalidad) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionCalidad.includes(selectedAutorizacionCalidad)
      );
    }
    if (selectedAutorizacionPloter) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionPloter.includes(selectedAutorizacionPloter)
      );
    }
    if (selectedAutorizacionPloterDiseno) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionPloterDiseno.includes(selectedAutorizacionPloterDiseno)
      );
    }
    if (selectedAutorizacionCostos) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionCostos.includes(selectedAutorizacionCostos)
      );
    }
    if (selectedAutorizacionSurtir) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionSurtir.includes(selectedAutorizacionSurtir)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "surtido" &&
      sorting.field != "pendiente_surtir" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

  const data = computedComments.map((a) => {
    {
      return [
        a.numero,
        a.resurtido,
        a.fecha,
        a.clientes,
        a.colaboradores,
        a.articulo,
        a.cantidad,
        a.fecha_cancelacion,
        a.fecha_confirmacion,
        a.comprador,
        a.departamento,
        a.subDepartamento,
        a.clase,
        a.observaciones,
        a.patronista,
        a.muestrista,
        a.ploter,
        a.statusTela
      ];
    }
  });
  const dataPDF = Object.values(data);
  const dataPDFLimpia = dataPDF.filter(function (el) {
    return el != null;
  });
  const doc = new jsPDF("landscape");
  var img = new Image();
  img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  doc.addImage(img, "png", 230, 30, 45, 15);
  var img2 = new Image();
  img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  doc.addImage(img2, "png", 120, 15, 60, 25);
  doc.text(`Listado Pedidos`, 20, 30);
  doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
  doc.autoTable({
    head: [
      [
        "Numero",
        "Resurtido",
        "Fecha",
        "Cliente",
        "Vendedor",
        "Articulo",
        "Cantidad",
        "Fecha Canc.",
        "Fecha Conf.",
        "Copmrador",
        "Departamento",
        "SubDepartamento",
        "Clase",
        "Observaciones",
        "Patronista",
        "Muestrista",
        "Ploter",
        "Status Tela",
      ],
    ],
    body: dataPDFLimpia,
    startY: 45,
  });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    idColaboradores,
    fecha_cancelacion,
    fecha_confirmacion,
    idClientes,
    total_kilos,
    total_metros,
    total_general,
    observaciones,
    numero_pedido,
    idEdit,
    idMuestrista,
    idPatronista,
    idPloter,
    idArticulo,
    costo
  ) {
    setFecha(fecha);
    setSelectedColaboradorEdit(idColaboradores);
    setFechaCancelacion(fecha_cancelacion);
    setFechaConfirmacion(fecha_confirmacion);
    setSelectedClienteEdit(idClientes);
    setTotalUnidaKilos(total_kilos);
    setTotalUnidaMetros(total_metros);
    setTotalGeneral(total_general);
    setObservaciones(observaciones);
    setNumeroPedido(numero_pedido);
    setIdEdit(idEdit);
    setSelectedPatronistaEdit(idPatronista);
    setSelectedMuestristaEdit(idMuestrista);
    setSelectedPloterEdit(idPloter);
    setIdArticuloEdit(idArticulo);
    setCostoEdit(costo);
    toggleEdit();
  }

  // async function PDFOC(
  //   fecha,
  //   colaboradores,
  //   fecha_cancelacion,
  //   fecha_confirmacion,
  //   idClientes,
  //   clientes,
  //   total_kilos,
  //   total_metros,
  //   total_general,
  //   observaciones,
  //   numero,
  //   idPDFOC
  // ) {
  //   await axios
  //     .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${idPDFOC}`, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       let allArticulosPedido = response.data;
  //       let arrayTabla = allArticulosPedido
  //         .map((a) => {
  //           return {
  //             id: a._id,
  //             articulos: a.articulos[0].codigoCliente + " " + a.articulos[0].codigo,
  //             unidad: a.articulos[0].unidad,
  //             colores: a.colores[0].name,
  //             tallas: a.tallas[0].name,
  //             cantidad: a.cantidad,
  //             surtido: a.surtido,
  //             precio: a.precio,
  //             descuento: a.descuento,
  //             total: a.total,
  //             pendiente_surtir: a.pendiente_surtir,
  //           };
  //         })
  //         .filter(function (el) {
  //           return el != null;
  //         });

  //       let dataFinal = Object.values(arrayTabla);

  //       let mts = dataFinal.filter((e) => e.unidad == "Metros");
  //       let totalMts = mts.map((c) => parseFloat(c.cantidad));
  //       let TM = totalMts.reduce((t, total, index) => t + total, 0);
  //       setTotalMetrosEdit(TM);
  //       let KG = dataFinal.filter((e) => e.unidad == "Kilos");
  //       let totalKG = KG.map((c) => parseFloat(c.cantidad));
  //       let TK = totalKG.reduce((t, total, index) => t + total, 0);
  //       setTotalKilosEdit(TK);
  //       let importes = dataFinal.map((c) => parseFloat(c.total));
  //       let TG = importes.reduce((t, total, index) => t + total, 0);
  //       setTotalGeneralEdit(TG);

  //       const data = dataFinal.map((a) => {
  //         return [
  //           a.articulos,
  //           a.colores,
  //           a.tallas,
  //           new Intl.NumberFormat("en-US").format(a.cantidad),
  //           new Intl.NumberFormat("en-US").format(a.surtido),
  //           "$" + new Intl.NumberFormat("en-US").format(a.precio),
  //           a.descuento + " %",
  //           "$" + new Intl.NumberFormat("en-US").format(a.total),
  //         ];
  //       });
  //       const dataPDF = Object.values(data);
  //       const dataPDFLimpia = dataPDF.filter(function (el) {
  //         return el != null;
  //       });
  //       const doc = new jsPDF("landscape");
  //       var img = new Image();
  //       img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
  //       doc.addImage(img, "png", 230, 30, 45, 15);
  //       var img2 = new Image();
  //       img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
  //       doc.addImage(img2, "png", 120, 15, 60, 25);
  //       doc.text(`Pedido # ${numero}`, 20, 20);
  //       doc.setFontSize(12);
  //       doc.text(`Fecha ${fecha}`, 20, 25);
  //       doc.text(`Cliente ${clientes}`, 20, 30);
  //       doc.text(
  //         `Vendedor ${colaboradores}, Cancelacion ${fecha_cancelacion}, Confirmacion ${fecha_confirmacion}`,
  //         20,
  //         35
  //       );
  //       doc.text(
  //         `Metros ${new Intl.NumberFormat("en-US").format(
  //           TM
  //         )} / Kilos ${new Intl.NumberFormat("en-US").format(TK)}`,
  //         20,
  //         40
  //       );
  //       doc.setFont(undefined, "bold");
  //       doc.text(`Observaciones ${observaciones}`, 20, 45);
  //       doc.autoTable({
  //         head: [
  //           [
  //             "Articulo",
  //             "Color",
  //             "Talla",
  //             "Cantidad",
  //             "Surtido",
  //             "Precio",
  //             "Descuento",
  //             "Total",
  //           ],
  //         ],
  //         body: dataPDFLimpia,
  //         startY: 50,
  //         foot: [
  //           [
  //             "",
  //             "",
  //             "",
  //             "",
  //             "",
  //             "Total",
  //             "$" + new Intl.NumberFormat("en-US").format(total_general),
  //           ],
  //         ],
  //         showFoot: "lastPage",
  //       });
  //       doc.save(`Pedido-${numero}.pdf`);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  function editPedidos(event) {
    event.preventDefault();
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
    if (file == null) {
      axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            fecha,
            colaboradores: selectedColaboradorEdit,
            fecha_cancelacion,
            fecha_confirmacion,
            clientes: selectedClienteEdit,
            total_general,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          axios
            .patch(
              `${URL_ARTICULOS}/${idArticuloEdit}`,
              {
                patronista: selectedPatronistaEdit,
                muestrista: selectedMuestristaEdit,
                ploter: selectedPloterEdit,
                costo: costoEdit
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )

            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Pedido",
                  detalle: `${numero_pedido} ${fecha}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );

              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {
      axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            fecha,
            colaboradores: selectedColaboradorEdit,
            fecha_cancelacion,
            fecha_confirmacion,
            clientes: selectedClienteEdit,
            // total_kilos,
            // total_metros,
            total_general,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("articulo", `${idArticuloEdit}-ficha`);

          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios
                .patch(
                  `${URL_ARTICULOS}/${idArticuloEdit}`,
                  {
                    patronista: selectedPatronistaEdit,
                    muestrista: selectedMuestristaEdit,
                    ploter: selectedPloterEdit,
                    fotoDiseno: "Si",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )

                .then(() => {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido} ${fecha}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );

                  toggleEdit();
                  Swal.fire("Good job!", "Actualizado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Resurtido", field: "resurtido", sortable: true },
    { name: "Fecha Cita Venta", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: false },
    { name: "Articulo", field: "articulo", sortable: false },
    { name: "Cantidad", field: "cantidad", sortable: false },
    { name: "Fecha Cancelacion", field: "fecha_cancelacion", sortable: true },
    { name: "Fecha Confirmacion", field: "fecha_confirmacion", sortable: true },

    { name: "Comprador", field: "comprador", sortable: false },
    { name: "Departamento", field: "departamento", sortable: false },
    { name: "Subdepartamento", field: "subDepartamento", sortable: false },
    { name: "Clase", field: "clase", sortable: false },
    { name: "Observaciones", field: "observaciones", sortable: false },

    { name: "Patronista", field: "patronista", sortable: true },
    { name: "Muestrista", field: "muestrista", sortable: true },
    { name: "Ploter", field: "ploter", sortable: true },
    // { name: "Status Tela", field: "statusTela", sortable: true },

    { name: "Aut. Diseño", field: "autorizacionDiseno", sortable: true },
    // { name: "Aut. Fit Compras", field: "autorizacionFit", sortable: true },
    // { name: "Aut. Habilitacion", field: "autorizacionCalidad", sortable: true },
    { name: "Aut. Trazo", field: "autorizacionPloter", sortable: true },
    // { name: "Aut. Ploter Diseño", field: "autorizacionPloterDiseno", sortable: true },
    // { name: "Aut. Costos", field: "autorizacionCostos", sortable: true },
    // { name: "Aut. Surtir", field: "autorizacionSurtir", sortable: true },

    { name: "Autorizaciones", field: "autorizaciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.articulos.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.fecha_cancelacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.fecha_confirmacion
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.patronista.toLowerCase().includes(search.toLowerCase()) ||
          comment.muestrista.toLowerCase().includes(search.toLowerCase()) ||
          comment.ploter.toLowerCase().includes(search.toLowerCase()) ||
          comment.statusTela.toLowerCase().includes(search.toLowerCase()) ||
          comment.comprador.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamento.toLowerCase().includes(search.toLowerCase()) ||
          comment.subDepartamento
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.clase.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.cantidad.toString().includes(search) ||
          comment.surtido.toString().includes(search) ||
          comment.pendiente_surtir.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    
    if (selectedPatronistaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idPatronista.includes(selectedPatronistaFiltro)
      );
    }

    if (selectedMuestristaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idMuestrista.includes(selectedMuestristaFiltro)
      );
    }

    if (selectedPloterFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idPloter.includes(selectedPloterFiltro)
      );
    }

    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    // if (activo) {
    //   computedComments = computedComments.filter((e) =>
    //     e.activo.includes(activo)
    //   );
    // }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFechaConfInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha_confirmacion >= selectedFechaConfInicio
      );
    }

    if (selectedFechaConfFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha_confirmacion <= selectedFechaConfFin
      );
    }

    if (selectedStatusTela) {
      computedComments = computedComments.filter(
        (e) => e.statusTela.includes(selectedStatusTela)
      );
    }
    if (selectedResurtido) {
      computedComments = computedComments.filter(
        (e) => e.resurtido.includes(selectedResurtido)
      );
    }
    if (selectedAutorizacionDiseno) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionDiseno.includes(selectedAutorizacionDiseno)
      );
    }
    if (selectedAutorizacionFit) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionFit.includes(selectedAutorizacionFit)
      );
    }
    if (selectedAutorizacionCalidad) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionCalidad.includes(selectedAutorizacionCalidad)
      );
    }
    if (selectedAutorizacionPloter) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionPloter.includes(selectedAutorizacionPloter)
      );
    }
    if (selectedAutorizacionPloterDiseno) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionPloterDiseno.includes(selectedAutorizacionPloterDiseno)
      );
    }
    if (selectedAutorizacionCostos) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionCostos.includes(selectedAutorizacionCostos)
      );
    }
    if (selectedAutorizacionSurtir) {
      computedComments = computedComments.filter(
        (e) => e.autorizacionSurtir.includes(selectedAutorizacionSurtir)
      );
    }

    let sal = computedComments.map((c) => parseFloat(c.cantidad));
    let TSal = sal.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalTotalCantidad(TSal);

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "cantidad" &&
      sorting.field != "surtido" &&
      sorting.field != "pendiente_surtir" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cantidad" ||
        sorting.field == "surtido" ||
        sorting.field == "pendiente_surtir" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedArticulo,
    // activo,
    selectedFechaInicio,
    selectedFechaFin,
    selectedFechaConfInicio,
    selectedFechaConfFin,
    selectedStatusTela,
    selectedResurtido,
    selectedAutorizacionCostos,
    selectedAutorizacionFit,
    selectedAutorizacionDiseno,
    selectedAutorizacionCalidad,
    selectedAutorizacionPloterDiseno,
    selectedAutorizacionPloter,
    selectedAutorizacionSurtir,
    selectedPatronistaFiltro,
    selectedMuestristaFiltro,
    selectedPloterFiltro
  ]);

  const handleChangeInputExistentes = (id, event) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  function BuscaArticuloExistentes(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputExistentesArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo,
          tallasArticulo
        );
      }
    });
  }

  const handleChangeInputExistentesArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newArticulosPedido = articulosPedido.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
        i.pendiente_surtir = i.cantidad - i.surtido;
      }
      return i;
    });
    setArticulosPedido(newArticulosPedido);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        resurtido: ultimo.resurtido,
        articulos: ultimo.articulos,
        unidad: ultimo.unidad,
        colores: "",
        arrayColoresArticulo: ultimo.arrayColoresArticulo,
        tallas: "",
        arrayTallasArticulo: ultimo.arrayTallasArticulo,
        cantidad: 0,
        precio: ultimo.precio,
        descuento: ultimo.descuento,
        total: 0,
        codigoBarras: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mtsExistentes = articulosPedido.filter((e) => e.unidad == "Metros");
    let mtsNuevos = values.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosPedido.filter((e) => e.unidad == "Kilos");
    let KGNuevos = values.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    articulos.map((a) => {
      if (a._id == event.target.value) {
        let idArticulo = a._id;
        let unidadArticulo = a.unidad;
        let coloresArticulo = a.colores;
        let tallasArticulo = a.tallas;
        handleChangeInputArticulo(
          id,
          idArticulo,
          unidadArticulo,
          coloresArticulo,
          tallasArticulo
        );
      }
    });
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    unidadArticulo,
    coloresArticulo,
    tallasArticulo
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo;
        i.arrayColoresArticulo = coloresArticulo;
        i.arrayTallasArticulo = tallasArticulo;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(
          2
        );
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let mtsExistentes = articulosPedido.filter((e) => e.unidad == "Metros");
    let mtsNuevos = inputFields.filter((e) => e.unidad == "Metros");
    let mts = mtsExistentes.concat(mtsNuevos);
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);

    let KGExistentes = articulosPedido.filter((e) => e.unidad == "Kilos");
    let KGNuevos = inputFields.filter((e) => e.unidad == "Kilos");
    let KG = KGExistentes.concat(KGNuevos);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let importesExistentes = articulosPedido.map((c) => parseFloat(c.total));
    let importesNuevos = inputFields.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  }

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosPedido.length;
    const URL_PEDIDOS_EDIT = `${process.env.REACT_APP_URL_PEDIDOS}/${idEdit}`;
    try {
      await axios
        .patch(
          URL_PEDIDOS_EDIT,
          {
            total_general: total_general_edit,
            // total_kilos: total_kilos_edit,
            // total_metros: total_metros_edit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          inputFields.map((a) => {
            if (a.articulos != "" && a.colores != "" && a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: idEdit,
                    articulos: a.articulos,
                    colores: a.colores,
                    tallas: a.tallas,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    descuento: parseFloat(a.descuento),
                    total: parseFloat(a.total),
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    codigoBarras: a.codigoBarras,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Pedido",
                        detalle: `${numero_pedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Pedido",
                    detalle: `${numero_pedido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosPedido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/${a.id}`,
                {
                  articulos: a.articulos,
                  colores: a.colores,
                  tallas: a.tallas,
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  descuento: parseFloat(a.descuento),
                  total: parseFloat(a.total),
                  pendiente_surtir: parseFloat(a.pendiente_surtir),
                  codigoBarras: a.codigoBarras,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Pedido",
                      detalle: `${numero_pedido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function abierto(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Abrirá!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Abrir!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              status: "Abierto",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/baja/${id}`,
                {
                  is_active: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.status = "Abierto";
                  }
                  return i;
                });
                setComments(newComments);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se Abrió",
                  showConfirmButton: false,
                  timer: 2000,
                });
                // setTimeout(() => {
                //   window.location.reload();
                // }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function cerrar(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Cerrará!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PEDIDOS}/${id}`,
            {
              status: "Cerrado",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            axios
              .patch(
                `${URL_ARTICULOS_PEDIDO}/baja/${id}`,
                {
                  is_active: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                const newComments = comments.map((i) => {
                  if (id === i._id) {
                    i.status = "Cerrado";
                  }
                  return i;
                });
                setComments(newComments);

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Se Cerró",
                  showConfirmButton: false,
                  timer: 2000,
                });

                // setTimeout(() => {
                //   window.location.reload();
                // }, 1000);
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  async function requisicion(id, numero) {
    setExplosion([]);
    setExplosionHab([]);
    toggleProgreso();
    setIdEdit(id);
    setNumeroPedido(numero);
    axios
      .get(`${URL_ARTICULOS_PEDIDO}/pedidos/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = await allArticulosPedido
          .map((a) => {
            return {
              id: a._id,
              activo: a.is_active,
              articulos: a.articulos[0]._id,
              articulosCodigo: a.articulos[0].codigo,
              colores: a.colores[0]._id,
              coloresName: a.colores[0].name,
              tallas: a.tallas[0]._id,
              tallasName: a.tallas[0].name,
              cantidad: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["articulos"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          return groups;
        }, []);

        let dataFinal = Object.values(agrupado);

        let agrupadoColores = data.reduce(function (groups, item) {
          const val = item["articulos"] + item["colores"];
          groups[val] = groups[val] || {
            articulos: item.articulos,
            colores: item.colores,
            cantidad: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].articulos = item.articulos;
          groups[val].articulosCodigo = item.articulosCodigo;
          groups[val].colores = item.colores;
          groups[val].coloresName = item.coloresName;
          return groups;
        }, []);

        let dataFinalColores = Object.values(agrupadoColores);

        let temp2 = [];
        let temp3 = [];

        let exp = await dataFinal.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.habilitacion != "") {
                    temp2.push({
                      articulos: a.articulos,
                      articulosCodigo: a.articulosCodigo,
                      material: b.habilitacion[0].nombre  + " " + b.habilitacion[0].descripcion,
                      cantidad: b.cantidad * a.cantidad,
                      observaciones: b.observaciones,
                      colores: "NA",
                      coloresName: "NA",
                      tipo: "Habilitacion",
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
        let expColores = await dataFinalColores.map((a) => {
          axios
            .get(`${URL_EXPLOSION}Articulo/${a.articulos}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((response) => {
              let allExplosion = response.data;
              let arrayExp = allExplosion
                .map((b) => {
                  if (b.telas != "") {
                    temp3.push({
                      articulos: a.articulos,
                      articulosCodigo: a.articulosCodigo,
                      material: b.telas[0].nombre,
                      cantidad: b.cantidad * a.cantidad,
                      colores: a.colores,
                      coloresName: a.coloresName,
                      observaciones: "NA",
                      tipo: "Tela",
                    });
                  }
                })
                .filter(function (el) {
                  return el != null;
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
        setExplosion(temp3);
        setExplosionHab(temp2);
        setTimeout(() => {
          setModalProgreso(false);
          toggleExplosion();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function autorizacion(idPedido) {
    await axios
      .get(`${URL_PEDIDOS}/${idPedido}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setAutorizacionId(idPedido);
        setAutorizacionCostos(response.data.autorizacionCostos);
        setAutorizacionDiseno(response.data.autorizacionDiseno);
        setAutorizacionCalidad(response.data.autorizacionCalidad);
        setAutorizacionPloter(response.data.autorizacionPloter);
        setAutorizacionPloterDiseno(response.data.autorizacionPloterDiseno);
        setAutorizacionFit(response.data.autorizacionFit);
        setAutorizacionSurtir(response.data.autorizacionSurtir);

        setAutorizacionCostosColaborador(
          response.data.autorizacionCostosColaborador[0].nombre +
            " " +
            response.data.autorizacionCostosColaborador[0].apellido
        );
        setAutorizacionDisenoColaborador(
          response.data.autorizacionDisenoColaborador[0].nombre +
            " " +
            response.data.autorizacionDisenoColaborador[0].apellido
        );
        setAutorizacionCalidadColaborador(
          response.data.autorizacionCalidadColaborador[0].nombre +
            " " +
            response.data.autorizacionCalidadColaborador[0].apellido
        );
        setAutorizacionPloterColaborador(
          response.data.autorizacionPloterColaborador[0].nombre +
            " " +
            response.data.autorizacionPloterColaborador[0].apellido
        );
        setAutorizacionPloterDisenoColaborador(
          response.data.autorizacionPloterDisenoColaborador[0].nombre +
            " " +
            response.data.autorizacionPloterDisenoColaborador[0].apellido
        );
        setAutorizacionFitColaborador(
          response.data.autorizacionFitColaborador[0].nombre +
            " " +
            response.data.autorizacionFitColaborador[0].apellido
        );
        setAutorizacionSurtirColaborador(
          response.data.autorizacionSurtirColaborador[0].nombre +
            " " +
            response.data.autorizacionSurtirColaborador[0].apellido
        );

        setAutorizacionCostosFecha(response.data.autorizacionCostosFecha);
        setAutorizacionDisenoFecha(response.data.autorizacionDisenoFecha);
        setAutorizacionCalidadFecha(response.data.autorizacionCalidadFecha);
        setAutorizacionPloterFecha(response.data.autorizacionPloterFecha);
        setAutorizacionPloterDisenoFecha(
          response.data.autorizacionPloterDisenoFecha
        );
        setAutorizacionFitFecha(response.data.autorizacionFitFecha);
        setAutorizacionSurtirFecha(response.data.autorizacionSurtirFecha);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleAutorizaciones();
  }

  function jalaFoto(idEdit) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}-ficha`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEditArticulo(idEdit);
    toggleFoto();
  }

  function jalaFichaTecnica(idArticulo) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idArticulo}-ficha`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEditArticulo(idEdit);
    toggleFichaTecnica();
  }

  function autCostos(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionCostos: "Si",
          autorizacionCostosFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionCostosColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  function desAutCostos(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionCostos: "No",
          autorizacionCostosFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionCostosColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function autDiseno(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionDiseno: "Si",
          autorizacionDisenoFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionDisenoColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  function desAutDiseno(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionDiseno: "No",
          autorizacionDisenoFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionDisenoColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function autCalidad(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionCalidad: "Si",
          autorizacionCalidadFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionCalidadColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  function desAutCalidad(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionCalidad: "No",
          autorizacionCalidadFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionCalidadColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function autPloter(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionPloter: "Si",
          autorizacionPloterFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionPloterColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  function desAutPloter(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionPloter: "No",
          autorizacionPloterFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionPloterColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function autPloterDiseno(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionPloterDiseno: "Si",
          autorizacionPloterDisenoFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionPloterDisenoColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  function desAutPloterDiseno(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionPloterDiseno: "No",
          autorizacionPloterDisenoFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionPloterDisenoColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function autSurtir(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionSurtir: "Si",
          autorizacionSurtirFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionSurtirColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  function desAutSurtir(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionSurtir: "No",
          autorizacionSurtirFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionSurtirColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function autFit(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionFit: "Si",
          autorizacionFitFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionFitColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  function desAutFit(id) {
    axios
      .patch(
        `${URL_PEDIDOS}/${id}`,
        {
          autorizacionFit: "No",
          autorizacionFitFecha: moment(hoy).format("YYYY-MM-DD"),
          autorizacionFitColaborador: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Actualizado con Exito",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(() => {
          setModalAutorizaciones(false);
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaFotoPedido(idEdit) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEdit(idEdit);
    toggleFotoPedido();
  }


  function saveImagenes() {
      Swal.fire({
        title: `Por favor espere...`,
        html: "data uploading", // add html attribute if you want or remove
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const formData = new FormData();
      formData.append("file", fileImagenes);
      formData.append("articulo", `${idEdit}-ImagenesDiseno`);

      axios
      .post(URL_UPMEDIA, formData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
        .then(() => {
          // setImagenesDiseno("Si");
          axios
            .patch(
              `${URL_PEDIDOS}/${idEdit}`,
              {
                imagenesDiseno: "Si",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: `Se guardaron los datos`,
                showConfirmButton: false,
                timer: 1000,
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
  }

  function jalaImagenesDiseno(idPedido) {
    setPhotoImagenes("");
    // const URL_GET_MEDIA = `${URL_GETMEDIA}/${idCaso}-${tipoFoto}`;
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idPedido}-ImagenesDiseno`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhotoImagenes(data.data.resources);
      })
      .catch((err) => console.log(err));
    toggleVerFotoImagenes();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_pedidos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={desarrollo}
                >
                  Desarrollo
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Pedidos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Pedidos Diseño</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Fecha Conf Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaConfInicio}
                required
                onChange={(e) => {
                  setselectedFechaConfInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Conf Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaConfFin}
                required
                onChange={(e) => {
                  setselectedFechaConfFin(e.target.value);
                }}
              />
            </Col>
            {/* <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedActivo}
                required
                onChange={(e) => {
                  setSelectedActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col> */}
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedResurtido}
                  onChange={(e) => {
                    setSelectedResurtido(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
                </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedArticulo}
                onChange={(e) => {
                  setSelectedArticulo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {articulos
                  .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.codigo} {a.codigoCliente} {a.descripcion}
                      </option>
                    );
                  })}
              </Input>
                </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPatronistaFiltro}
                onChange={(e) => {
                  setSelectedPatronistaFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedMuestristaFiltro}
                onChange={(e) => {
                  setSelectedMuestristaFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
                </td>
                <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPloterFiltro}
                onChange={(e) => {
                  setSelectedPloterFiltro(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
                </td>
              {/* <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedStatusTela}
                  onChange={(e) => {
                    setSelectedStatusTela(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Pedido">Pedido</option>
                  <option value="Recibido">Recibido</option>
                  
                </Input>
              </td> */}
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAutorizacionDiseno}
                  onChange={(e) => {
                    setSelectedAutorizacionDiseno(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td>
              {/* <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAutorizacionFit}
                  onChange={(e) => {
                    setSelectedAutorizacionFit(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td> */}
              {/* <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAutorizacionCalidad}
                  onChange={(e) => {
                    setSelectedAutorizacionCalidad(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td> */}
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAutorizacionPloter}
                  onChange={(e) => {
                    setSelectedAutorizacionPloter(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td>
              {/* <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAutorizacionPloterDiseno}
                  onChange={(e) => {
                    setSelectedAutorizacionPloterDiseno(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAutorizacionCostos}
                  onChange={(e) => {
                    setSelectedAutorizacionCostos(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAutorizacionSurtir}
                  onChange={(e) => {
                    setSelectedAutorizacionSurtir(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                  
                </Input>
              </td> */}
            </tr>
            <tbody>
              {commentsData.map((a) => {
                return (
                  // <tr style={{ backgroundColor: "#ed8c8c" }}>
                  <tr>
                    <td>{a.numero}</td>
                    <td>{a.resurtido}</td>
                    <td>{a.fecha}</td>
                    <td>{a.clientes}</td>
                    <td>{a.colaboradores}</td>
                    <td>{a.articulos}</td>
                    <td>{a.cantidad}</td>
                    <td>{a.fecha_cancelacion}</td>
                    <td>{a.fecha_confirmacion}</td>
                    <td>{a.comprador}</td>
                    <td>{a.departamento}</td>
                    <td>{a.subDepartamento}</td>
                    <td>{a.clase}</td>
                    <td>{a.observaciones}</td>
                    <td>{a.patronista}</td>
                    <td>{a.muestrista}</td>
                    <td>{a.ploter}</td>
                    {/* <td>{a.statusTela}</td> */}

                    <td>{a.autorizacionDiseno}</td>
                    {/* <td>{a.autorizacionFit}</td> */}
                    {/* <td>{a.autorizacionCalidad}</td> */}
                    <td>{a.autorizacionPloter}</td>
                    {/* <td>{a.autorizacionPloterDiseno}</td>
                    <td>{a.autorizacionCostos}</td>
                    <td>{a.autorizacionSurtir}</td> */}
                    <td>
                      <>
                      <Button
                        color="danger"
                        id="Editar"
                        size="sm"
                        onClick={(e) => jalaFotoPedido(a.idArticulo)}
                        >
                        <i class="fas fa-camera"></i>
                        </Button>
                        {a.fotoDiseno == "No" ? (
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            // onClick={(e) => jalaFoto(a.idArticulo)}
                            disabled
                          >
                            <i class="fas fa-tshirt"></i>
                          </Button>
                        ) : (
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) => jalaFoto(a.idArticulo)}
                          >
                            <i class="fas fa-tshirt"></i>
                          </Button>
                        )}
                        <Button
                          color="primary"
                          id="Editar"
                          size="sm"
                          onClick={(e) => requisicion(a._id, a.numero)}
                        >
                          <i class="fas fa-tag"></i>
                        </Button>
                        <Button
                          color="info"
                          id="Editar"
                          size="sm"
                          onClick={(e) =>
                            jalaInfo(
                              a.fecha,
                              a.idColaboradores,
                              a.fecha_cancelacion,
                              a.fecha_confirmacion,
                              a.idClientes,
                              a.total_kilos,
                              a.total_metros,
                              a.total_general,
                              a.observaciones,
                              a.numero,
                              a._id,
                              a.idMuestrista,
                              a.idPatronista,
                              a.idPloter,
                              a.idArticulo,
                              a.costo
                            )
                          }
                        >
                          <i class="far fa-edit"></i>
                        </Button>
                        <Button
                          color="info"
                          id="Editar"
                          size="sm"
                          onClick={(e) => jalaImagenesDiseno(a._id)}
                        >
                          <i class="fas fa-camera"></i>
                        </Button>

                        <Button
                          color="success"
                          id="Telas"
                          size="sm"
                          onClick={(e) => autorizacion(a._id)}
                        >
                          <i class="far fa-check-circle"></i>
                        </Button>
                      </>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="right">
                  TOTAL GENERAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTotalCantidad)}
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Pedido {numero_pedido}</h4>
        </ModalHeader>
        <ModalBody>
          {/* <Row>
            <Col md={4}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedClienteEdit}
                required
                onChange={(e) => {
                  setSelectedClienteEdit(e.target.value);
                }}
              >
                <option value="">Selecciona un Cliente</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
            </Col>
            <Col md={4}>
              <Label>Vendedor</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColaboradorEdit}
                required
                onChange={(e) => {
                  setSelectedColaboradorEdit(e.target.value);
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>
          </Row> */}
          {/* <Row>
            <Col md={4}>
              <Label>Fecha Cancelacion</Label>
              <Input
                type="date"
                placeholder="Fecha Cancelacion"
                value={fecha_cancelacion}
                required
                onChange={(e) => {
                  setFechaCancelacion(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>Fecha Confirmacion</Label>
              <Input
                type="date"
                placeholder="Fecha Confirmacion"
                value={fecha_confirmacion}
                required
                onChange={(e) => {
                  setFechaConfirmacion(e.target.value);
                }}
              />
            </Col>
          </Row> */}
          <Row>
            <Col md={4}>
              <Label>Patronista</Label>
              <Input
                type="select"
                value={selectedPatronistaEdit}
                onChange={(e) => {
                  setSelectedPatronistaEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={4}>
              <Label>Muestrista</Label>
              <Input
                type="select"
                value={selectedMuestristaEdit}
                onChange={(e) => {
                  setSelectedMuestristaEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>

            <Col md={4}>
              <Label>Ploter</Label>
              <Input
                type="select"
                value={selectedPloterEdit}
                onChange={(e) => {
                  setSelectedPloterEdit(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                  })}
              </Input>
            </Col>
          </Row>
          <Row>
            
          <Col md={3}>
          <Label className="mr-sm-2">Costo</Label>
                    <Input
                      name="costo"
                      type="number"
                      value={costoEdit}
                      onChange={(e) => {
                        setCostoEdit(e.target.value);
                      }}
                    ></Input>
                  </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label>Ficha Tecnica</Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row>
          <Row>
          <Col md={6}>
                      <Label >
                        Imagenes
                      </Label>
                      <Input
                        type="file"
                        onChange={(e) => {
                          setFileImagenes(e.target.files[0]);
                        }}
                      />
                    </Col>
                    <Button
                        color="gris"
                        className="btn btn-gris"
                        size="sm"
                        onClick={(e) => {
                          saveImagenes();
                        }}
                      >
                      Subir
                      </Button>
          </Row>
          {/* <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <Label>Foto</Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
          </Row> */}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editPedidos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>Imagen del Articulo</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/palassiArticulos/${idEditArticulo}-ficha`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido una Imagen del Articulo....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalFichaTecnica} toggle={toggleFichaTecnica}>
        <ModalHeader toggle={toggleFichaTecnica}>
          <h4>Ficha Tecnica del Articulo</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/palassiArticulos/${idEditArticulo}-ficha`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido una Ficha Tecnica del Articulo....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>Explosion</h4>
        </ModalHeader>
        <ModalBody>
          <h5>Tela</h5>
          <Table size="lg" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Material</th>
              <th>Color</th>
              <th>Cantidad</th>
              <th>Observaciones</th>
            </tr>
            {explosion.map((a) => (
              <tr>
                <td>{a.articulosCodigo}</td>
                <td>{a.material}</td>
                <td>{a.coloresName}</td>
                <td>{a.cantidad}</td>
                <td>{a.observaciones}</td>
              </tr>
            ))}
          </Table>
          <br />

          <h5>Habilitacion</h5>
          <Table size="lg" striped borderless className="table-responsive-xl">
            <tr>
              <th>Articulo</th>
              <th>Material</th>
              <th>Color</th>
              <th>Cantidad</th>
              <th>Observaciones</th>
            </tr>
            {explosionHab.map((a) => (
              <tr>
                <td>{a.articulosCodigo}</td>
                <td>{a.material}</td>
                <td>{a.coloresName}</td>
                <td>{a.cantidad}</td>
                <td>{a.observaciones}</td>
              </tr>
            ))}
          </Table>
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalAutorizaciones}
        toggle={toggleAutorizaciones}
      >
        <ModalHeader toggle={toggleAutorizaciones}>
          <h4>Autorizaciones</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3} className="negrita">
              <Label className="mr-sm-2">Concepto</Label>
            </Col>
            <Col md={3} className="negrita">
              <Label className="mr-sm-2">Autorizacion</Label>
            </Col>
            <Col md={3} className="negrita">
              <Label className="mr-sm-2">Colaborador</Label>
            </Col>
            <Col md={3} className="negrita">
              <Label className="mr-sm-2">Fecha</Label>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Diseño</Label>
            </Col>
            <Col md={3}>
              {autorizacionDiseno == "Si" ? (
                <Button
                  color="primary"
                  onClick={(e) => desAutDiseno(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={(e) => autDiseno(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              )}
            </Col>

            <Col md={3} className="negro">
              {autorizacionDisenoColaborador}
            </Col>
            <Col md={3} className="negro">
              {autorizacionDisenoFecha}
            </Col>
          </Row>


          {/* <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Habilitacion</Label>
            </Col>
            <Col md={3}>
              {autorizacionCalidad == "Si" ? (
                <Button
                  color="primary"
                  onClick={(e) => desAutCalidad(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={(e) => autCalidad(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              )}
            </Col>

            <Col md={3} className="negro">
              {autorizacionCalidadColaborador}
            </Col>
            <Col md={3} className="negro">
              {autorizacionCalidadFecha}
            </Col>
          </Row> */}

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Trazo</Label>
            </Col>
            <Col md={3}>
              {autorizacionPloter == "Si" ? (
                <Button
                  color="primary"
                  onClick={(e) => desAutPloter(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={(e) => autPloter(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              )}
            </Col>
            <Col md={3} className="negro">
              {autorizacionPloterColaborador}
            </Col>
            <Col md={3} className="negro">
              {autorizacionPloterFecha}
            </Col>
          </Row>

          {/* <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Ploter Diseño</Label>
            </Col>
            <Col md={3}>
              {autorizacionPloterDiseno == "Si" ? (
                <Button
                  color="primary"
                  onClick={(e) => desAutPloterDiseno(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={(e) => autPloterDiseno(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              )}
            </Col>
            <Col md={3} className="negro">
              {autorizacionPloterDisenoColaborador}
            </Col>
            <Col md={3} className="negro">
              {autorizacionPloterDisenoFecha}
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Costos</Label>
            </Col>
            <Col md={3}>
              {autorizacionCostos == "Si" ? (
                <Button
                  color="primary"
                  onClick={(e) => desAutCostos(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={(e) => autCostos(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              )}
            </Col>
            <Col md={3} className="negro">
              {autorizacionCostosColaborador}
            </Col>
            <Col md={3} className="negro">
              {autorizacionCostosFecha}
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Surtir</Label>
            </Col>
            <Col md={3}>
              {autorizacionSurtir == "Si" ? (
                <Button
                  color="primary"
                  onClick={(e) => desAutSurtir(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={(e) => autSurtir(autorizacionId)}
                  size="sm"
                >
                  <i class="fas fa-check-circle"></i>
                </Button>
              )}
            </Col>
            <Col md={3} className="negro">
              {autorizacionSurtirColaborador}
            </Col>
            <Col md={3} className="negro">
              {autorizacionSurtirFecha}
            </Col>
          </Row> */}
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalFotoPedido} toggle={toggleFotoPedido}>
        <ModalHeader toggle={toggleFotoPedido}>
          <h4>Foto Pedido</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/palassiArticulos/${idEdit}`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido el Pedido del Cliente....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalVerFotoImagenes} toggle={toggleVerFotoImagenes}>
        <ModalHeader toggle={toggleVerFotoImagenes}>
          <h4>Imagenes Diseño</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
          {photoImagenes.length > 0 ? (
              photoImagenes.map((a)=>{
                return(
                <h6 style={{ textAlign: "center" }}>
                <img
                  src= {a.secure_url}
                  width="250"
                  height="auto"
                   />
              </h6>
              )                
              })
            ) : <h4>No se ha subido una Foto....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoPedidosDiseno;
