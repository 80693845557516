import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import ListadoUsuariosAdmin from '../components/layout/Login/ListadoUsuariosAdmin'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// {/* Catalogos */}
import BotonesCatalogos from "./views/Catalogos/BotonesCatalogos"
import ListadoPeriodosAdmin from './views/Catalogos/PeriodosAdmin/ListadoPeriodosAdmin'
import ListadoAreas from './views/Catalogos/Areas/ListadoAreas'
import PeriodosAdminCreate from './views/Catalogos/PeriodosAdmin/PeriodosAdminCreate'
import ListadoPlanteles from './views/Catalogos/Planteles/ListadoPlanteles'
import ListadoCiclos from './views/Catalogos/Ciclos/ListadoCiclos'
import ListadoProveedores from './views/Catalogos/Proveedores/ListadoProveedores'
import ProveedoresCreate from './views/Catalogos/Proveedores/ProveedoresCreate'

// Materiales
import BotonesMateriales from "./views/Materiales/BotonesMateriales";
import ListadoColores from './views/Materiales/Colores/ListadoColores'
import ListadoTiposTelas from './views/Materiales/TiposTelas/ListadoTiposTelas'
import ListadoTelas from './views/Materiales/Telas/ListadoTelas'
import TelasCreate from './views/Materiales/Telas/TelasCreate'
import ListadoHabilitacion from './views/Materiales/Habilitacion/ListadoHabilitacion'
import ListadoSalidaHabilitacion from './views/Materiales/Habilitacion/ListadoSalidaHabilitacion'
import HabilitacionCreate from './views/Materiales/Habilitacion/HabilitacionCreate'
import ListadoInventariosTelas from './views/Materiales/Telas/ListadoInventariosTelas'
import ListadoInventariosTelasPicos from './views/Materiales/Telas/ListadoInventariosTelasPicos'
import ListadoInventariosTelasStock from './views/Materiales/Telas/ListadoInventariosTelasStock'
import ListadoInventariosHabilitacion from "./views/Materiales/Habilitacion/ListadoInventariosHabilitacion";

// {/* Articulos */}
import BotonesArticulos from "./views/Articulos/BotonesArticulos"
import ListadoArticulos from './views/Articulos/Articulos/ListadoArticulos'
import ArticulosCreate from './views/Articulos/Articulos/ArticulosCreate'
import ArticulosCreateSolo from './views/Articulos/Articulos/ArticulosCreateSolo'
import ListadoDibujos from './views/Articulos/Dibujos/ListadoDibujos'
import ListadoMarcas from './views/Articulos/Marcas/ListadoMarcas'
import ListadoColecciones from './views/Articulos/Colecciones/ListadoColecciones'
import ListadoTallas from "./views/Articulos/Tallas/ListadoTallas";
import TallasCreate from "./views/Articulos/Tallas/TallasCreate";
import ListadoDepartamentos from "./views/Articulos/Departamentos/ListadoDepartamentos";
import DepartamentosCreate from "./views/Articulos/Departamentos/DepartamentosCreate";
import ListadoSubDepartamentos from "./views/Articulos/SubDepartamentos/ListadoSubDepartamentos";
import SubDepartamentosCreate from "./views/Articulos/SubDepartamentos/SubDepartamentosCreate";
import ListadoClases from "./views/Articulos/Clases/ListadoClases";
import ClasesCreate from "./views/Articulos/Clases/ClasesCreate";
import ListadoSubClases from "./views/Articulos/SubClases/ListadoSubClases";
import SubClasesCreate from "./views/Articulos/SubClases/SubClasesCreate";
import ListadoArticulosPadre from "./views/Articulos/ArticulosPadre/ListadoArticulosPadre";
import ArticulosPadreCreate from "./views/Articulos/ArticulosPadre/ArticulosPadreCreate";

// {/* Inventarios */}
import BotonesInventarios from "./views/Inventarios/BotonesInventarios"
import ListadoDisponible from "./views/Inventarios/InventariosTelas/ListadoDisponible";
import ListadoSeguimiento from "./views/Inventarios/InventariosTelas/ListadoSeguimiento";
import ListadoDisponibleVendedor from "./views/Inventarios/InventariosTelas/ListadoDisponibleVendedor";
import ListadoPendRecibir from "./views/Inventarios/InventariosTelas/ListadoPendRecibir";
import ListadoInventarios from "./views/Inventarios/Inventarios/ListadoInventarios";
import ListadoUbicaciones from "./views/Inventarios/Inventarios/ListadoUbicaciones";
import ListadoUbicaciones2 from "./views/Inventarios/Inventarios/ListadoUbicaciones2";
import ListadoSeguimientoTelas from "./views/Inventarios/InventariosTelas/ListadoSeguimientoTelas";

// {/* Compras */}
import BotonesCompras from "./views/Compras/BotonesCompras"
import ListadoOrdenesCompra from './views/Compras/OrdenesCompra/ListadoOrdenesCompra'
import OrdenesCompraCreate from './views/Compras/OrdenesCompra/OrdenesCompraCreate'
import BotonesComprasDirectas from "./views/ComprasDirectas/BotonesComprasDirectas"
import ListadoOrdenesCompraDirectas from './views/ComprasDirectas/OrdenesCompraDirectas/ListadoOrdenesCompraDirectas'
import OrdenesCompraDirectasCreate from './views/ComprasDirectas/OrdenesCompraDirectas/OrdenesCompraDirectasCreate'
import ListadoComprasDirectas from "./views/ComprasDirectas/ComprasDirectas/ListadoComprasDirectas";
import ComprasDirectasCreate from "./views/ComprasDirectas/ComprasDirectas/ComprasDirectasCreate";
import ListadoCompras from "./views/Compras/Compras/ListadoCompras";
import ReporteComprasMaterial from "./views/Compras/Compras/ReporteComprasMaterial";
import ListadoComprasFaltante from "./views/Compras/Compras/ListadoComprasFaltante";
import ComprasCreate from "./views/Compras/Compras/ComprasCreate";
import ComprasContenedorCreate from "./views/Compras/Compras/ComprasContenedorCreate";
import ListadoDevolucionesProveedores from "./views/Compras/DevolucionesProveedores/ListadoDevolucionesProveedores";
import DevolucionesProveedoresCreate from "./views/Compras/DevolucionesProveedores/DevolucionesProveedoresCreate";
import DevolucionesProveedoresHabCreate from "./views/Compras/DevolucionesProveedores/DevolucionesProveedoresHabCreate";
import CorteDiario from "./views/Compras/CorteDiario";

// Produccion
import BotonesProduccion from "./views/Produccion/BotonesProduccion"
import ListadoMaquileros from "./views/Produccion/Maquileros/ListadoMaquileros";
import MaquilerosCreate from "./views/Produccion/Maquileros/MaquilerosCreate";
import ListadoCatalogoProcesos from "./views/Produccion/CatalogoProcesos/ListadoCatalogoProcesos";
import CatalogoProcesosCreate from "./views/Produccion/CatalogoProcesos/CatalogoProcesosCreate";
import ListadoOrdenesCorte from "./views/Produccion/OrdenesCorte/ListadoOrdenesCorte";
import OrdenesCorteCreate from "./views/Produccion/OrdenesCorte/OrdenesCorteCreate";
import ListadoCortes from "./views/Produccion/Cortes/ListadoCortes";
import ListadoArticulosCortes from "./views/Produccion/Cortes/ListadoArticulosCortes";
import CortesCreate from "./views/Produccion/Cortes/CortesCreate";
import CortesPendientesRecibir from "./views/Produccion/Cortes/CortesPendientesRecibir";
import ListadoApartados from './views/Produccion/Apartados/ListadoApartados'
import ApartadosCreate from './views/Produccion/Apartados/ApartadosCreate'


// {/* Ventas */}
import BotonesVentas from "./views/Ventas/BotonesVentas"
import ListadoPedidos from './views/Ventas/Pedidos/ListadoPedidos'
import ListadoPedidosDiseno from './views/Ventas/Pedidos/ListadoPedidosDiseno'
import ListadoPedidosHabilitacion from './views/Ventas/Pedidos/ListadoPedidosHabilitacion'
import PedidosCreate from './views/Ventas/Pedidos/PedidosCreate'
import VentaSalderos from './views/Ventas/Pedidos/VentaSalderos'
import ListadoSurtidos from './views/Ventas/Surtidos/ListadoSurtidos'
import ListadoPendienteSurtir from "./views/Ventas/Surtidos/ListadoPendienteSurtir";
import ListadoPendientePedidoCliente from "./views/Ventas/Surtidos/ListadoPendientePedidoCliente";
import ListadoPedidoCliente from "./views/Ventas/Surtidos/ListadoPedidoCliente";
import ListadoDevoluciones from './views/Ventas/Devoluciones/ListadoDevoluciones'
import DevolucionesCreate from './views/Ventas/Devoluciones/DevolucionesCreate'
import ListadoCompradores from "./views/Ventas/Compradores/ListadoCompradores";
import CompradoresCreate from "./views/Ventas/Compradores/CompradoresCreate";

// {/* Admin */}
import BotonesGastos from "./views/ConfigAdmin/BotonesGastos"
import ListadoPresupuestos from './views/ConfigAdmin/Presupuestos/ListadoPresupuestos'
import PresupuestosCreate from './views/ConfigAdmin/Presupuestos/PresupuestosCreate'
import ListadoGastos from './views/ConfigAdmin/Gastos/ListadoGastos'
import GastosCreate from './views/ConfigAdmin/Gastos/GastosCreate'
import ListadoConceptosGastos from './views/ConfigAdmin/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/ConfigAdmin/ConceptosGastos/ConceptosGastosCreate'
import ListadoEmpresas from "./views/Admin/Empresas/ListadoEmpresas";

//Nominas
import BotonesNominas from './views/Empleados/BotonesNominas'
import ListadoNominas from "./views/Empleados/Nominas/ListadoNominas"
import ListadoColaboradores from "./views/Empleados/Colaboradores/ListadoColaboradores"
import ListadoFaltasColaboradores from './views/Empleados/Faltas/ListadoFaltasColaboradores'
import FaltasColaboradoresCreate from './views/Empleados/Faltas/FaltasColaboradoresCreate'
import ListadoPuestos from "./views/Empleados/Puestos/ListadoPuestos"
import NominasCreate from "./views/Empleados/Nominas/NominasCreate"
import ColaboradoresCreate from "./views/Empleados/Colaboradores/ColaboradoresCreate"

// Clientes
import BotonesClientes from './views/Clientes/BotonesClientes'
import ListadoClientes from "./views/Clientes/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/Clientes/ClientesCreate"


// Logs
import Logs from './views/Logs/ListadoLogs'

// Config
import Configuraciones from './views/Configuracion/Configuraciones'

// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import AbonosCreate from './views/Admin/Abonos/AbonosCreate'
import ListadoAbonos from './views/Admin/Abonos/ListadoAbonos'
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";
import ListadoVentas from "./views/Admin/Ventas/ListadoVentas";
import NotasCargoCreate from './views/Admin/NotasCargo/NotasCargoCreate'
import ListadoNotasCargo from './views/Admin/NotasCargo/ListadoNotasCargo'
import ListadoDevolucionesAdmin from './views/Admin/DevolucionesAdmin/ListadoDevolucionesAdmin'
import ListadoConceptosBonificaciones from "./views/Admin/ConceptosBonificaciones/ListadoConceptosBonificaciones";
import BonificacionesCreate from "./views/Admin/Bonificaciones/BonificacionesCreate";
import ListadoBonificaciones from "./views/Admin/Bonificaciones/ListadoBonificaciones";

// CXP
import BotonesCXP from "./views/CXP/BotonesCXP";
import EstadoCuentaCXP from "./views/CXP/EstadoCuentaCXP/EstadoCuentaCXP";
import EstadoCuentaMaq from "./views/CXP/EstadoCuentaMaq/EstadoCuentaMaq";
import ListadoComprasCXP from "./views/CXP/ComprasCXP/ListadoComprasCXP";
import AbonosProveedoresCreate from './views/CXP/AbonosProveedores/AbonosProveedoresCreate'
import BonificacionesProveedoresCreate from './views/CXP/AbonosProveedores/BonificacionesProveedoresCreate'
import ListadoAbonosProveedores from './views/CXP/AbonosProveedores/ListadoAbonosProveedores'
import ListadoDevolucionesProveedoresAdmin from './views/CXP/DevolucionesProveedoresAdmin/ListadoDevolucionesProveedoresAdmin'
import ListadoNotasCxP from "./views/CXP/NotasCxP/ListadoNotasCxP";
import AbonosMaquilerosCreate from './views/CXP/AbonosMaquileros/AbonosMaquilerosCreate'
import ListadoAbonosMaquileros from './views/CXP/AbonosMaquileros/ListadoAbonosMaquileros'
import AnticiposProveedoresCreate from "./views/CXP/AnticiposProveedores/AnticiposProveedoresCreate";
import ListadoAnticiposProveedores from "./views/CXP/AnticiposProveedores/ListadoAnticiposProveedores";
import ListadoFaltantesProveedores from "./views/CXP/AnticiposProveedores/ListadoFaltantesProveedores";
import DocumentacionCreate from "./views/CXP/Documentacion/DocumentacionCreate";
import ListadoDocumentacion from "./views/CXP/Documentacion/ListadoDocumentacion";

// Traspasos
import TraspasosSalidaCreate from './views/Inventarios/Traspasos/TraspasosSalidaCreate'
import ListadoTraspasosSalida from './views/Inventarios/Traspasos/ListadoTraspasosSalida'
import TraspasosEntradaCreate from './views/Inventarios/Traspasos/TraspasosEntradaCreate'
import ListadoTraspasosEntrada from './views/Inventarios/Traspasos/ListadoTraspasosEntrada'

// Fiscal
import BotonesFiscal from "./views/Fiscal/BotonesFiscal";
import ListadoFacturas from "./views/Fiscal/Facturacion/ListadoFacturas.jsx";
import FacturasCreate from "./views/Fiscal/Facturacion/FacturasCreate.jsx";
import ListadoComplementosPago from "./views/Fiscal/ComplementosPago/ListadoComplementosPago.jsx";
import ComplementosPagoCreate from "./views/Fiscal/ComplementosPago/ComplementosPagoCreate.jsx";
import NotasCreditoCreate from "./views/Fiscal/NotasCredito/NotasCreditoCreate.jsx";
import ListadoNotasCredito from "./views/Fiscal/NotasCredito/ListadoNotasCredito.jsx";
import DevolucionesFiscalCreate from "./views/Fiscal/DevolucionesFiscal/DevolucionesFiscalCreate.jsx";
import ListadoDevolucionesFiscal from "./views/Fiscal/DevolucionesFiscal/ListadoDevolucionesFiscal.jsx";
import RegistrarEmpresas from "./views/Fiscal/RegistrarEmpresa/RegistrarEmpresas.jsx";

// Retiros
import BotonesRetiros from "./views/Retiros/BotonesRetiros.jsx";
import ListadoSocios from "./views/Retiros/Socios/ListadoSocios.jsx";
import ListadoRetiros from "./views/Retiros/Retiros/ListadoRetiros.jsx";
import RetirosCreate from "./views/Retiros/Retiros/RetirosCreate.jsx";
import ListadoPorRetirar from "./views/Retiros/PorRetirar/ListadoPorRetirar.jsx";
import PorRetirarCreate from "./views/Retiros/PorRetirar/PorRetirarCreate.jsx";
import ListadoSociedades from "./views/Retiros/Sociedades/ListadoSociedades.jsx";
import SociedadesCreate from "./views/Retiros/Sociedades/SociedadesCreate.jsx";

// Ventas Telas
import BotonesVentasTelas from "./views/VentasTelas/BotonesVentasTelas"
import ListadoPedidosTelas from './views/VentasTelas/PedidosTelas/ListadoPedidosTelas'
import PedidosTelasCreate from './views/VentasTelas/PedidosTelas/PedidosTelasCreate'
import ListadoSurtidosTelas from './views/VentasTelas/SurtidosTelas/ListadoSurtidosTelas'
import ListadoDevolucionesTelas from './views/VentasTelas/DevolucionesTelas/ListadoDevolucionesTelas'
import DevolucionesTelasCreate from './views/VentasTelas/DevolucionesTelas/DevolucionesTelasCreate'
import ListadoApartadosTelas from './views/VentasTelas/ApartadosTelas/ListadoApartadosTelas'
import ApartadosTelasCreate from './views/VentasTelas/ApartadosTelas/ApartadosTelasCreate'

function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/MenuUsuarios" component={ListadoUsuarios} />
                <Route exact path ="/MenuUsuariosAdmin" component={ListadoUsuariosAdmin} />
                {/* Catalogos */}
                {/* <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} /> */}
                <Route exact path ="/Planteles" component={ListadoPlanteles} />
                <Route exact path ="/Areas" component={ListadoAreas} />
                <Route exact path ="/Ciclos" component={ListadoCiclos} />
                <Route exact path ="/PeriodosAdmin" component={ListadoPeriodosAdmin} />
                <Route exact path ="/PeriodosAdminCreate" component={PeriodosAdminCreate} />
                <Route exact path ="/Proveedores" component={ListadoProveedores} />
                <Route exact path ="/ProveedoresCreate" component={ProveedoresCreate} />
                {/* Materiales */}
                <Route exact path ="/MenuCatalogos" component={BotonesMateriales} />
                <Route exact path ="/Colores" component={ListadoColores} />
                <Route exact path ="/TiposTelas" component={ListadoTiposTelas} />
                <Route exact path ="/ListadoTelas" component={ListadoTelas} />
                <Route exact path ="/TelasCreate" component={TelasCreate} />
                <Route exact path ="/ListadoHabilitacion" component={ListadoHabilitacion} />
                <Route exact path ="/ListadoSalidaHabilitacion" component={ListadoSalidaHabilitacion} />
                <Route exact path ="/HabilitacionCreate" component={HabilitacionCreate} />
                <Route exact path ="/ListadoInventariosTelas" component={ListadoInventariosTelas} />
                <Route exact path ="/ListadoInventariosTelasPicos" component={ListadoInventariosTelasPicos} />
                <Route exact path ="/ListadoInventariosTelasStock" component={ListadoInventariosTelasStock} />
                <Route exact path ="/ListadoInventariosHabilitacion" component={ListadoInventariosHabilitacion} />
                {/* Articulos */}
                <Route exact path ="/MenuCatalogos" component={BotonesArticulos} />
                <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                <Route exact path ="/ArticulosCreate" component={ArticulosCreate} />
                <Route exact path ="/ArticulosCreateSolo" component={ArticulosCreateSolo} />
                <Route exact path ="/Dibujos" component={ListadoDibujos} />
                <Route exact path ="/Marcas" component={ListadoMarcas} />
                <Route exact path ="/Colecciones" component={ListadoColecciones} />
                <Route exact path ="/ListadoTallas" component={ListadoTallas} />
                <Route exact path ="/TallasCreate" component={TallasCreate} />
                <Route exact path ="/ListadoDepartamentos" component={ListadoDepartamentos} />
                <Route exact path ="/DepartamentosCreate" component={DepartamentosCreate} />
                <Route exact path ="/ListadoSubDepartamentos" component={ListadoSubDepartamentos} />
                <Route exact path ="/SubDepartamentosCreate" component={SubDepartamentosCreate} />
                <Route exact path ="/ListadoClases" component={ListadoClases} />
                <Route exact path ="/ClasesCreate" component={ClasesCreate} />
                <Route exact path ="/ListadoSubClases" component={ListadoSubClases} />
                <Route exact path ="/SubClasesCreate" component={SubClasesCreate} />
                <Route exact path ="/ListadoArticulosPadre" component={ListadoArticulosPadre} />
                <Route exact path ="/ArticulosPadreCreate" component={ArticulosPadreCreate} />
                {/* InventariosTelas */}
                <Route exact path ="/MenuInventarios" component={BotonesInventarios} />
                <Route exact path ="/ListadoInventarios" component={ListadoInventarios} />
                <Route exact path ="/ListadoDisponible" component={ListadoDisponible} />
                <Route exact path ="/ListadoDisponibleVendedor" component={ListadoDisponibleVendedor} />
                <Route exact path ="/ListadoSeguimiento" component={ListadoSeguimiento} />
                <Route exact path ="/ListadoPendRecibir" component={ListadoPendRecibir} />
                <Route exact path ="/ListadoUbicaciones" component={ListadoUbicaciones} />
                <Route exact path ="/ListadoUbicaciones2" component={ListadoUbicaciones2} />
                <Route exact path ="/ListadoSeguimientoTelas" component={ListadoSeguimientoTelas} />
                {/* Compras */}
                <Route exact path ="/MenuCompras" component={BotonesCompras} />
                <Route exact path ="/ListadoOrdenesCompra" component={ListadoOrdenesCompra} />
                <Route exact path ="/OrdenesCompraCreate" component={OrdenesCompraCreate} />
                <Route exact path ="/MenuComprasDirectas" component={BotonesComprasDirectas} />
                <Route exact path ="/ListadoOrdenesCompraDirectas" component={ListadoOrdenesCompraDirectas} />
                <Route exact path ="/OrdenesCompraDirectasCreate" component={OrdenesCompraDirectasCreate} />
                <Route exact path ="/ListadoComprasDirectas" component={ListadoComprasDirectas} />
                <Route exact path ="/ComprasDirectasCreate" component={ComprasDirectasCreate} />
                <Route exact path ="/ListadoCompras" component={ListadoCompras} />
                <Route exact path ="/ReporteComprasMaterial" component={ReporteComprasMaterial} />
                <Route exact path ="/ListadoComprasFaltante" component={ListadoComprasFaltante} />
                <Route exact path ="/ComprasCreate" component={ComprasCreate} />
                <Route exact path ="/ComprasContenedorCreate" component={ComprasContenedorCreate} />
                <Route exact path ="/ListadoDevolucionesProveedores" component={ListadoDevolucionesProveedores} />
                <Route exact path ="/DevolucionesProveedoresCreate" component={DevolucionesProveedoresCreate} />
                <Route exact path ="/DevolucionesProveedoresHabCreate" component={DevolucionesProveedoresHabCreate} />
                <Route exact path ="/CorteDiario" component={CorteDiario} />
                {/* Produccion */}
                <Route exact path ="/MenuProduccion" component={BotonesProduccion} />
                <Route exact path ="/Maquileros" component={ListadoMaquileros} />
                <Route exact path ="/MaquilerosCreate" component={MaquilerosCreate} />
                <Route exact path ="/CatalogoProcesos" component={ListadoCatalogoProcesos} />
                <Route exact path ="/CatalogoProcesosCreate" component={CatalogoProcesosCreate} />
                <Route exact path ="/ListadoOrdenesCorte" component={ListadoOrdenesCorte} />
                <Route exact path ="/OrdenesCorteCreate" component={OrdenesCorteCreate} />
                <Route exact path ="/ListadoCortes" component={ListadoCortes} />
                <Route exact path ="/ListadoArticulosCortes" component={ListadoArticulosCortes} />
                <Route exact path ="/CortesCreate" component={CortesCreate} />
                <Route exact path ="/CortesPendientesRecibir" component={CortesPendientesRecibir} />
                <Route exact path ="/AnticiposProveedoresCreate" component={AnticiposProveedoresCreate} />
                <Route exact path ="/ListadoAnticiposProveedores" component={ListadoAnticiposProveedores} />
                <Route exact path ="/ListadoFaltantesProveedores" component={ListadoFaltantesProveedores} />
                <Route exact path ="/ListadoApartados" component={ListadoApartados} />
                <Route exact path ="/ApartadosCreate" component={ApartadosCreate} />
                {/* Ventas */}
                <Route exact path ="/MenuVentas" component={BotonesVentas} />
                <Route exact path ="/ListadoPedidos" component={ListadoPedidos} />
                <Route exact path ="/ListadoPedidosDiseno" component={ListadoPedidosDiseno} />
                <Route exact path ="/ListadoPedidosHabilitacion" component={ListadoPedidosHabilitacion} />
                <Route exact path ="/PedidosCreate" component={PedidosCreate} />
                <Route exact path ="/VentaSalderos" component={VentaSalderos} />
                <Route exact path ="/ListadoSurtidos" component={ListadoSurtidos} />
                <Route exact path ="/ListadoPendienteSurtir" component={ListadoPendienteSurtir} />
                <Route exact path ="/ListadoPendientePedidoCliente" component={ListadoPendientePedidoCliente} />
                <Route exact path ="/ListadoPedidoCliente" component={ListadoPedidoCliente} />
                <Route exact path ="/ListadoDevoluciones" component={ListadoDevoluciones} />
                <Route exact path ="/DevolucionesCreate" component={DevolucionesCreate} />
                <Route exact path ="/ListadoCompradores" component={ListadoCompradores} />
                <Route exact path ="/CompradoresCreate" component={CompradoresCreate} />
                
                {/* Admin */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/Presupuestos" component={ListadoPresupuestos} />
                <Route exact path ="/PresupuestosCreate" component={PresupuestosCreate} />
                <Route exact path ="/MenuNominas" component={BotonesNominas} />
                <Route exact path ="/Nominas" component={ListadoNominas} />
                <Route exact path ="/Empleados" component={ListadoColaboradores} />
                <Route exact path ="/EmpleadosCreate" component={ColaboradoresCreate} />
                <Route exact path ="/FaltasColaboradores" component={ListadoFaltasColaboradores} />
                <Route exact path ="/FaltasColaboradoresCreate" component={FaltasColaboradoresCreate} />
                <Route exact path ="/NominasCreate" component={NominasCreate} />
                <Route exact path ="/Puestos" component={ListadoPuestos} />
                <Route exact path ="/Gastos" component={ListadoGastos} />
                <Route exact path ="/GastosCreate" component={GastosCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                <Route exact path ="/ListadoConceptosBonificaciones" component={ListadoConceptosBonificaciones} />
                <Route exact path ="/ListadoBonificaciones" component={ListadoBonificaciones} />
                <Route exact path ="/BonificacionesCreate" component={BonificacionesCreate} />
                {/* Clientes */}
                <Route exact path ="/MenuClientes" component={BotonesClientes} />
                <Route exact path ="/Clientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                {/* Config  */}
                <Route exact path ="/MenuConfiguraciones" component={Configuraciones} />
                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                <Route exact path ="/ListadoVentas" component={ListadoVentas} />
                <Route exact path ="/NotasCargoCreate" component={NotasCargoCreate} />
                <Route exact path ="/ListadoNotasCargo" component={ListadoNotasCargo} />
                <Route exact path ="/ListadoDevolucionesAdmin" component={ListadoDevolucionesAdmin} />
                <Route exact path ="/ListadoEmpresas" component={ListadoEmpresas} />
                {/* CXP */}
                <Route exact path ="/MenuCXP" component={BotonesCXP} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />
                <Route exact path ="/EstadoCuentaMaq" component={EstadoCuentaMaq} />
                <Route exact path ="/ListadoComprasCXP" component={ListadoComprasCXP} />
                <Route exact path ="/AbonosProveedoresCreate" component={AbonosProveedoresCreate} />
                <Route exact path ="/BonificacionesProveedoresCreate" component={BonificacionesProveedoresCreate} />
                <Route exact path ="/ListadoAbonosProveedores" component={ListadoAbonosProveedores} />
                <Route exact path ="/ListadoDevolucionesProveedoresAdmin" component={ListadoDevolucionesProveedoresAdmin} />
                <Route exact path ="/ListadoNotasCxP" component={ListadoNotasCxP} />
                <Route exact path ="/AbonosMaquilerosCreate" component={AbonosMaquilerosCreate} />
                <Route exact path ="/ListadoAbonosMaquileros" component={ListadoAbonosMaquileros} />
                <Route exact path ="/ListadoDocumentacion" component={ListadoDocumentacion} />
                <Route exact path ="/DocumentacionCreate" component={DocumentacionCreate} />
                 {/* Traspasos */}
                 <Route exact path ="/TraspasosSalidaCreate" component={TraspasosSalidaCreate} />
                <Route exact path ="/ListadoTraspasosSalida" component={ListadoTraspasosSalida} />
                <Route exact path ="/TraspasosEntradaCreate" component={TraspasosEntradaCreate} />
                <Route exact path ="/ListadoTraspasosEntrada" component={ListadoTraspasosEntrada} />
                  {/* Fiscal */}
                  <Route exact path ="/MenuFiscal" component={BotonesFiscal} />
                <Route exact path ="/ListadoFacturas" component={ListadoFacturas} />
                <Route exact path ="/FacturasCreate" component={FacturasCreate} />
                <Route exact path ="/ListadoComplementosPago" component={ListadoComplementosPago} />
                <Route exact path ="/ComplementosPagoCreate" component={ComplementosPagoCreate} />
                <Route exact path ="/ListadoNotasCredito" component={ListadoNotasCredito} />
                <Route exact path ="/NotasCreditoCreate" component={NotasCreditoCreate} />
                <Route exact path ="/ListadoDevolucionesFiscal" component={ListadoDevolucionesFiscal} />
                <Route exact path ="/DevolucionesFiscalCreate" component={DevolucionesFiscalCreate} />
                <Route exact path ="/RegistrarEmpresas" component={RegistrarEmpresas} />
                {/* Retiros */}
                <Route exact path ="/MenuRetiros" component={BotonesRetiros} />
                <Route exact path ="/Socios" component={ListadoSocios} />
                <Route exact path ="/Retiros" component={ListadoRetiros} />
                <Route exact path ="/RetirosCreate" component={RetirosCreate} />
                <Route exact path ="/PorRetirar" component={ListadoPorRetirar} />
                <Route exact path ="/PorRetirarCreate" component={PorRetirarCreate} />
                <Route exact path ="/Sociedades" component={ListadoSociedades} />
                <Route exact path ="/SociedadesCreate" component={SociedadesCreate} />
                
                {/* Ventas Telas */}
                <Route exact path ="/MenuVentasTelas" component={BotonesVentasTelas} />
                <Route exact path ="/ListadoPedidosTelas" component={ListadoPedidosTelas} />
                <Route exact path ="/PedidosTelasCreate" component={PedidosTelasCreate} />
                <Route exact path ="/ListadoSurtidosTelas" component={ListadoSurtidosTelas} />
                <Route exact path ="/ListadoDevolucionesTelas" component={ListadoDevolucionesTelas} />
                <Route exact path ="/DevolucionesTelasCreate" component={DevolucionesTelasCreate} />
                <Route exact path ="/ListadoApartadosTelas" component={ListadoApartadosTelas} />
                <Route exact path ="/ApartadosTelasCreate" component={ApartadosTelasCreate} />
         </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
