import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import SociosCreate from './SociosCreate';
import axios from 'axios';
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from 'reactstrap';
import Header from '../../../layout/Header/Header';
import SweetAlert from 'sweetalert2-react';
import Swal from 'sweetalert2';
import Baja from '../../Baja';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import ExportJsonExcel from 'js-export-excel';

function ListadoSocios() {
  const { user } = useContext(AuthContext);
  const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
  const URL_RETIROS = process.env.REACT_APP_URL_RETIROS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const [socios, setSocios] = useState([]);
  const [modal, setModal] = useState(false);
  const [name, setName] = useState('');
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [idEdit, setIdEdit] = useState();
  const [movs, setMovs] = useState([]);

  const [modalMovs, setModalMovs] = useState(false);
  const toggleMovs = () => setModalMovs(!modalMovs);

  useEffect(() => {
    axios
      .get(URL_SOCIOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((response) => {
        let allSocios = response.data;
        setSocios(allSocios);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editSocio(event) {
    event.preventDefault();
    const URL_SOCIOS_EDIT = `${process.env.REACT_APP_URL_SOCIOS}/${idEdit}`;
    axios
      .patch(
        URL_SOCIOS_EDIT,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: 'Editar Socio',
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
            },
          }
        );
        toggle();
        Swal.fire('Good job!', 'Actualizado con exito', 'success');
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, name) {
    setName(name);
    setIdEdit(idEdit);
    toggle();
  }

  function jalaMovs(id, name) {
    setName(name);

    axios
      .get(`${URL_ESTADO_CUENTA}Socio/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('app_token')}`,
        },
      })
      .then((response) => {
        console.log(response.data)
        let allMmovs = response.data;
        setMovs(allMmovs);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleMovs();
  }

  function PDFOC() {
    let TotalSaldo = 0;
    let TotalRetirado = 0;
    let TotalRepartido = 0;
    const data = movs.map((a) => {
      {
        TotalSaldo = TotalSaldo + a.reparto - a.retirado;
        TotalRetirado = TotalRetirado + a.retirado;
        TotalRepartido = TotalRepartido + a.reparto;
        return [
          a.fecha,
          a.observaciones,
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          }).format(a.reparto),
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          }).format(a.retirado),
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
          }).format(TotalSaldo),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF('landscape');
    var img = new Image();
    img.src = process.env.PUBLIC_URL + 'Logo-Sistemify-PDF.png';
    doc.addImage(img, 'png', 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + 'logoInstitucion.png';
    doc.addImage(img2, 'png', 140, 15, 25, 25);
    doc.text(`Retiros ${name}`, 20, 30);
    doc.autoTable({
      head: [['Fecha', 'Observaciones', 'Reparto', 'Retirado', 'Saldo']],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          '',
          'Total',
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            }).format(TotalRepartido),
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            }).format(TotalRetirado),
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
            }).format(TotalSaldo),
        ],
      ],
    });
    doc.save(`Retiros.pdf`);
  }

  function excel() {
    const dataExcel = movs.map((a) => {
      return {
        Fecha: a.fecha,
        Observaciones: a.observaciones,
        Reparto: a.reparto,
        Retirado: a.retirado,
      };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `Retiros ${name}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: 'Retiros',
        sheetFilter: ['Fecha', 'Observaciones', 'Reparto', 'Retirado'],
        sheetHeader: ['Fecha', 'Observaciones', 'Reparto', 'Retirado'],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  let totalPorRetirar = 0;
  let totalRealRetirado = 0;
  let totRetirado = 0;
  let totRepartido = 0;
  let saldo = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      <div className='container'>
        {user.menuSocios ? (
          <div className='row'>
            {
              <div className='col-md-10'>
                <div className='card'>
                  <div className='card-body'>
                    <h3 align='center'>Socios</h3>
                    <Table
                      size='sm'
                      striped
                      borderless
                      className='table-responsive-xl'
                    >
                      <thead>
                        <tr>
                          <th className='tituloTabla'>Nombre</th>
                          <th className='tituloTabla'>Por Retirar</th>
                          <th className='tituloTabla'>Retirado Real</th>
                          <th className='tituloTabla'>Saldo</th>
                          <th className='tituloTabla'>Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {socios
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            totalPorRetirar = totalPorRetirar + a.porRetirar;
                            totalRealRetirado =
                              totalRealRetirado + a.realRetirado;
                            return (
                              <tr>
                                <td>{a.name}</td>
                                <td>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 2,
                                  }).format(a.porRetirar)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 2,
                                  }).format(a.realRetirado)}
                                </td>
                                <td>
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 2,
                                  }).format(a.porRetirar - a.realRetirado)}
                                </td>
                                <td>
                                  <Button
                                    color='info'
                                    id='Editar'
                                    size='sm'
                                    onClick={(e) =>
                                      jalaInfo(a._id, a.name)
                                    }
                                  >
                                    <i class='far fa-edit'></i>{' '}
                                  </Button>

                                  <Button
                                    color='success'
                                    id='Editar'
                                    size='sm'
                                    onClick={(e) =>
                                      jalaMovs(a._id, a.name)
                                    }
                                  >
                                    <i class='fas fa-list'></i>{' '}
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        <tr>
                          <td></td>
                          <td className='negrita' align='center'>
                            {' '}
                            TOTAL
                          </td>
                          <td className='negrita'>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              maximumFractionDigits: 2,
                            }).format(totalPorRetirar)}
                          </td>
                          <td className='negrita'>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              maximumFractionDigits: 2,
                            }).format(totalRealRetirado)}
                          </td>
                          <td className='negrita'></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className='col-md-6 col-sm-6'>
              <div className='card'>
                <div className='card-body'>
                  <SociosCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size='sm' isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Editar Socio
        </ModalHeader>
        <ModalBody>
          <Label className='mr-sm-2'>Nombre</Label>
          <Input
            className='col-sm-12'
            type='text'
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='success' onClick={editSocio}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size='lg' isOpen={modalMovs} toggle={toggleMovs}>
        <ModalHeader toggle={toggleMovs}>
          <h4>Estado de Cuenta de {name}</h4>
        </ModalHeader>
        <ModalBody>
          <Button size='sm' className='btn' color='danger' onClick={PDFOC}>
            PDF <i class='far fa-file-pdf'></i>
          </Button>
          <Button size='sm' className='btn' color='primary' onClick={excel}>
            Excel <i class='far fa-file-excel'></i>
          </Button>
          <div className='card container'>
            <Table size='sm' striped borderless className='table-responsive-xl'>
              <thead>
                <tr>
                  <th className='tituloTabla'>Fecha</th>
                  <th className='tituloTabla'>Observaciones</th>
                  <th className='tituloTabla'>Reparto</th>
                  <th className='tituloTabla'>Retiro</th>
                  <th className='tituloTabla'>Saldo</th>
                </tr>
              </thead>
              <tbody>
                {movs.map((c) => {
                  totRetirado = totRetirado + c.retirado;
                  totRepartido = totRepartido + c.reparto;
                  saldo = saldo + c.reparto - c.retirado;
                  return (
                    <tr>
                      <td>{c.fecha}</td>
                      <td>{c.observaciones}</td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 2,
                        }).format(c.reparto)}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 2,
                        }).format(c.retirado)}
                      </td>
                      <td>{new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 2,
                        }).format(saldo)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td></td>
                  <td className='negrita'> TOTAL</td>
                  <td className='negrita'>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2,
                    }).format(totRepartido)}
                  </td>
                  <td className='negrita'>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2,
                    }).format(totRetirado)}
                  </td>
                  <td className='negrita'>
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2,
                    }).format(saldo)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='success' onClick={toggleMovs}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoSocios;
