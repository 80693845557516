import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_DIBUJOS = process.env.REACT_APP_URL_DIBUJOS;
  // const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_CATALOGO_PROCESOS = process.env.REACT_APP_URL_CATALOGO_PROCESOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_COMPRADORES = process.env.REACT_APP_URL_COMPRADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_DEPARTAMENTOS = process.env.REACT_APP_URL_DEPARTAMENTOS;
  // const URL_SUBDEPARTAMENTOS = process.env.REACT_APP_URL_SUBDEPARTAMENTOS;
  const URL_CLASES = process.env.REACT_APP_URL_CLASES;
  const URL_SUBCLASES = process.env.REACT_APP_URL_SUBCLASES;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;
  const URL_EXPLOSION_GLOBAL = process.env.REACT_APP_URL_EXPLOSION_GLOBAL;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_SOCIEDADES = process.env.REACT_APP_URL_SOCIEDADES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  const [fecha, setFecha] = useState(hoy);

  const [articulosGuardado, setArticulosGuardado] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [pedido, setPedido] = useState("NA");
  const [codigoCliente, setCodigoCliente] = useState("NA");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("NA");
  const [observaciones, setObservaciones] = useState("NA");
  // const [marcas, setMarcas] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  // const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [catalogoProcesos, setCatalogoProcesos] = useState([]);
  const [sociedades, setSociedades] = useState([]);
  const [selectedSociedad, setSelectedSociedad] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedVendedor, setSelectedVendedor] = useState("");

  const [compradores, setCompradores] = useState([]);
  const [selectedComprador, setSelectedComprador] = useState("");
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  // const [subDepartamentos, setSubDepartamentos] = useState([]);
  // const [selectedSubDepartamento, setSelectedSubDepartamento] = useState("");
  const [clases, setClases] = useState([]);
  const [selectedClase, setSelectedClase] = useState("");
  const [subClases, setSubClases] = useState([]);
  const [selectedSubClase, setSelectedSubClase] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [selectedEmpresa, setSelectedEmpresa] = useState("");

  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);
  const [arrayColoresNombre, setArrayColoresNombre] = useState([]);

  const [modalTallas, setModalTallas] = useState(false);
  const toggleTallas = () => setModalTallas(!modalTallas);
  const [arrayTallas, setArrayTallas] = useState([]);
  const [arrayTallasNombre, setArrayTallasNombre] = useState([]);

  const [modalProcesos, setModalProcesos] = useState(false);
  const toggleProcesos = () => setModalProcesos(!modalProcesos);
  const [arrayProcesos, setArrayProcesos] = useState(["6363fb596cdd150aab0461cd",]);
  const [arrayProcesosNombre, setArrayProcesosNombre] = useState(["Maquila",]);

  const [vista, setVista] = useState("Articulo");
  const [validaBoton, setValidaBoton] = useState(true);

  const [dataArtId, setDataArtId] = useState("");

  const [fecha_cancelacion, setFechaCancelacion] = useState("");
  const [fecha_confirmacion, setFechaConfirmacion] = useState("");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_piezas, setTotalPiezas] = useState(0);

  const [arrayProporcion, setArrayProporcion] = useState([]);
  const [arrayTotalColor, setArrayTotalColor] = useState([]);
  const [totalProporcion, setTotalProporcion] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [precioEtiqueta, setPrecioEtiqueta] = useState(0);
  const [precio, setPrecio] = useState(0);
  const [pendFechaConf, setPendFechaConf] = useState("");

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [telas, setTelas] = useState([]);
  const [explosion, setExplosion] = useState([]);
  const [explosionHab, setExplosionHab] = useState([]);
  const [modalExplosiones, setModalExplosiones] = useState(false);
  const toggleExplosiones = () => setModalExplosiones(!modalExplosiones);

  const [inputFieldsTela, setInputFieldsTela] = useState([
    {
      id: uuidv4(),
      idTela: "",
      idColor: "",
      cantidad: 0,
      cantidadPrenda: 0,
      observaciones: "NA",
    },
  ]);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idColor: "",
      colorNombre: "",
      idTalla: "",
      tallaNombre: "",
      cantidad: 0,
      totalColor: 0,
    },
  ]);

  useEffect(() => {
    axios
      .get(URL_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelas = res.data;
        setTelas(allTelas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_SOCIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSociedades = res.data;
        setSociedades(allSociedades);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CATALOGO_PROCESOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCatalogoProcesos = res.data;
        setCatalogoProcesos(allCatalogoProcesos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COMPRADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCompradores = res.data;
        setCompradores(allCompradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_DEPARTAMENTOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDep = response.data;
        setDepartamentos(allDep);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    // axios
    //   .get(URL_SUBDEPARTAMENTOS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((response) => {
    //     let allSubDep = response.data;
    //     setSubDepartamentos(allSubDep);
    //     //
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    axios
      .get(URL_CLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClase = response.data;
        setClases(allClase);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_SUBCLASES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSubClase = response.data;
        setSubClases(allSubClase);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_EMPRESAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmpresa = response.data;
        setEmpresas(allEmpresa);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function coloresSeleccionados(checked, idColor,name) {
    if (checked) {
      arrayColores.push(idColor);
      arrayColoresNombre.push(name)
    } else {
      const index = arrayColores.indexOf(idColor);
      if (index > -1) {
        arrayColores.splice(index, 1);
      }

      const indexName = arrayColoresNombre.indexOf(name);
      if (index > -1) {
        arrayColoresNombre.splice(index, 1);
      }
    }
  }

  function tallasSeleccionados(checked, idColor, name) {
    if (checked) {
      arrayTallas.push(idColor);
      arrayTallasNombre.push(name)
    } else {
      const index = arrayTallas.indexOf(idColor);
      if (index > -1) {
        arrayTallas.splice(index, 1);
      }

      const indexName = arrayTallasNombre.indexOf(name);
      if (index > -1) {
        arrayTallasNombre.splice(index, 1);
      }
    }
  }

  function procesosSeleccionados(checked, idColor, name) {
    if (checked) {
      arrayProcesos.push(idColor);
      arrayProcesosNombre.push(name)
    } else {
      const index = arrayProcesos.indexOf(idColor);
      if (index > -1) {
        arrayProcesos.splice(index, 1);
      }

      const indexName = arrayProcesosNombre.indexOf(name);
      if (index > -1) {
        arrayProcesosNombre.splice(index, 1);
      }
    }
  }

  const saveArticulos = async (event) => {
    event.preventDefault();
    // setValidaBoton(false);
    try {
      let tempFoto = "No";
      if (file != null) {
        tempFoto = "Si";
      }
      await axios
        .post(
          URL_ARTICULOS,
          {
            fecha,
            descripcion,
            compradores: selectedComprador,
            colaboradores: selectedVendedor,
            marcas: "65d8bdeb86de65fdd6715955",
            colecciones: selectedColeccion,
            colores: arrayColores,
            tallas: arrayTallas,
            catalogoProcesos: arrayProcesos.filter((item, index) => {
              return arrayProcesos.indexOf(item) === index;
            }),
            patronista: "641a4dc74f1a3c0014bd77ba",
            muestrista: "641a4dc74f1a3c0014bd77ba",
            ploter: "641a4dc74f1a3c0014bd77ba",
            clientes: selectedCliente,
            pedido,
            codigo,
            codigoCliente,
            departamentos: selectedDepartamento,
            subDepartamentos: "6578b80b26a9245bd3ddc6de",
            clases: selectedClase,
            subClases: selectedSubClase,
            fotoArticulo: tempFoto,
            costo: 0,
            // catalogoProcesos: "6363fb596cdd150aab0461cd",
            codigoSAT: "NA",
            codigoUnidad: "NA",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )

        .then((data) => {
          if (file != null) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("articulo", data.data._id);

            axios.post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            });
          }

          axios
            .get(`${URL_ARTICULOS}/${data.data._id}`, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then((res) => {
              const allArticulosGuardado = [res.data];
              setArticulosGuardado(allArticulosGuardado);
              setDataArtId(res.data._id);
              setVista("Pedido");

              const newInputFields2 = [];
              allArticulosGuardado[0].colores.map((a) => {
                allArticulosGuardado[0].tallas.map((b) => {
                  newInputFields2.push({
                    id: uuidv4(),
                    idColor: a._id,
                    colorNombre: a.name,
                    idTalla: b._id,
                    tallaNombre: b.name,
                    cantidad: 0,
                  });
                });
              });

              setInputFields(newInputFields2);

              const newInputFields3 = [];
              allArticulosGuardado[0].tallas.map((b) => {
                newInputFields3.push({
                  id: uuidv4(),
                  idTalla: b._id,
                  tallaNombre: b.name,
                  proporcion: 0,
                });
              });

              setArrayProporcion(newInputFields3);

              const newInputFields4 = [];
              allArticulosGuardado[0].colores.map((b) => {
                newInputFields4.push({
                  id: uuidv4(),
                  idColor: b._id,
                  colorNombre: b.name,
                  totalColor: 0,
                });
              });

              setArrayTotalColor(newInputFields4);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let inicio = new Date(fecha_cancelacion);
    let bla = inicio.setDate(inicio.getDate() - 60);
    let fechaStatus = moment(bla).format("YYYY-MM-DD");

    let totalPedido = inputFields.length + inputFieldsTela.length;

    try {
      await axios
        .post(
          URL_PEDIDOS,
          {
            fecha,
            fechaCreacion: hoy,
            resurtido: "No",
            empresas: selectedEmpresa,
            clientes: selectedCliente,
            colaboradores: selectedVendedor,
            fecha_cancelacion,
            fecha_confirmacion,
            total_general,
            total_piezas,
            compradores: selectedComprador,
            observaciones,
            articulos: dataArtId,
            pedidoCliente: pedido,
            surtido: 0,
            pendiente_surtir: total_piezas,
            confirmacionPedido: "No",
            status: "Abierto",
            fechaEstCodigos: fechaStatus,
            fechaEstFicha: fechaStatus,
            fechaEstAuditoria: fechaStatus,
            fechaEstMuestraFit: fechaStatus,
            fechaEstMuestraConfirmacion: fechaStatus,
            fechaEstEtiquetas: fechaStatus,
            fechaRealCodigos: fechaStatus,
            fechaRealFicha: fechaStatus,
            fechaRealAuditoria: fechaStatus,
            fechaRealMuestraFit: fechaStatus,
            fechaRealMuestraConfirmacion: fechaStatus,
            fechaRealEtiquetas: fechaStatus,
            autorizacionFitFecha: fechaStatus,
            autorizacionCostosFecha: fechaStatus,
            autorizacionDisenoFecha: fechaStatus,
            autorizacionCalidadFecha: fechaStatus,
            autorizacionPloterFecha: fechaStatus,
            autorizacionPloterDisenoFecha: fechaStatus,
            autorizacionSurtirFecha: fechaStatus,

            autorizacionFitColaborador: "613a9434225bce3df050c220",
            autorizacionCostosColaborador: "613a9434225bce3df050c220",
            autorizacionDisenoColaborador: "613a9434225bce3df050c220",
            autorizacionCalidadColaborador: "613a9434225bce3df050c220",
            autorizacionPloterColaborador: "613a9434225bce3df050c220",
            autorizacionPloterDisenoColaborador: "613a9434225bce3df050c220",
            autorizacionSurtirColaborador: "613a9434225bce3df050c220",
            descuento,
            pendFechaConf,
            sociedades: selectedSociedad,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad != 0) {
              axios
                .post(
                  URL_ARTICULOS_PEDIDO,
                  {
                    pedidos: data.data._id,
                    articulos: dataArtId,
                    colores: a.idColor,
                    tallas: a.idTalla,
                    cantidad: parseFloat(a.cantidad),
                    pendienteCortar: parseFloat(a.cantidad),
                    cortado: 0,
                    surtido: 0,
                    pendiente_surtir: parseFloat(a.cantidad),
                    cantidad_procesos: parseFloat(a.cantidad),
                    precio: parseFloat(precio),
                    descuento,
                    total: (parseFloat(a.cantidad) * parseFloat(precio) - (parseFloat(a.cantidad) * parseFloat(precio) * descuento) / 100).toFixed(2),
                    codigoBarras: 0,
                    precioEtiqueta: precioEtiqueta,
                    resurtido: "No",
                    codigoCliente,
                    sociedades: selectedSociedad,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Pedido",
                          detalle: `${selectedCliente} ${total_general}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire(
                          "Good job!",
                          `Creado con exito, Pedido ${data.data.idPedido}`,
                          `success`
                        );
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      });
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Pedido",
                      detalle: `${selectedCliente} ${total_general}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire(
                      "Good job!",
                      `Creado con exito, Pedido ${data.data.idPedido}`,
                      `success`
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  });
              }
            }
          });

          inputFieldsTela.map((a) => {
            if (
              a.idTela != "" &&
              a.idColor != "" &&
              a.cantidad > 0 &&
              a.cantidadPrenda > 0
            ) {
              axios
                .post(
                  URL_EXPLOSION_GLOBAL,
                  {
                    articulos: dataArtId,
                    pedidos: data.data._id,
                    telas: a.idTela,
                    colores: a.idColor,
                    cantidad: a.cantidad,
                    cantidadPrenda: a.cantidadPrenda,
                    tipo: "Tela",
                    observaciones: a.observaciones,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Agregar Explosion",
                          detalle: `${dataArtId} ${a.idTela}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire(
                          "Good job!",
                          `Creado con exito, Pedido ${data.data.idPedido}`,
                          `success`
                        );
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      });
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleChangeInputProporcion = (id, event) => {
    const ap = arrayProporcion.map((i) => {
      if (id === i.id) {
        i.proporcion = event.target.value;
      }
      return i;
    });
    setArrayProporcion(ap);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, color, event) => {
    const cant = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
      }
      return i;
    });
    setInputFields(cant);

    let KG = cant.filter((e) => e.idColor == color);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);

    const cantTot = arrayTotalColor.map((i) => {
      if (color == i.idColor) {
        i.totalColor = TK;
      }
      return i;
    });

    setArrayTotalColor(cantTot);
    ActualizaTotales();
  };

  const handleChangeInputTotalColor = (id, color, event) => {
    const tc = arrayTotalColor.map((i) => {
      if (id === i.id) {
        i.totalColor = event.target.value;
      }
      return i;
    });
    setArrayTotalColor(tc);

    let cantTalla1 = event.target.value / totalProporcion;

    arrayProporcion.map((ap) => {
      inputFields.map((inp) => {
        if (ap.idTalla == inp.idTalla && inp.idColor == color) {
          return (inp.cantidad = (cantTalla1 * ap.proporcion).toFixed(0));
        }
      });
    });

    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantidad = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cantidad.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let totalProp = arrayProporcion.map((c) => parseFloat(c.proporcion));
    let TP = totalProp.reduce((t, total, index) => t + total, 0);
    setTotalProporcion(TP);

    let totalParcial = TC * precio;
    let totalGen = (totalParcial - (totalParcial * descuento) / 100).toFixed(2);

    setTotalGeneral(totalGen);
  }

  function jalaPrecio(e) {
    setPrecio(e);

    let totalParcial = total_piezas * e;
    let totalGen = (totalParcial - (totalParcial * descuento) / 100).toFixed(2);

    let temp = total_piezas * e;
    setTotalGeneral(totalGen);
  }

  function explosiones() {
    // setInputFieldsTela([
    //   {
    //     id: uuidv4(),
    //     idTela: "",
    //     idColor: "",
    //     cantidad: 0,
    //     cantidadPrenda: 0,
    //     observaciones: "NA",
    //   },
    // ]);
    toggleExplosiones();
  }

  const handleRemoveFieldsTelas = (id) => {
    const values = [...inputFieldsTela];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsTela(values);
  };

  const handleAddFieldsTelas = () => {
    setInputFieldsTela([
      ...inputFieldsTela,
      {
        id: uuidv4(),
        idTela: "",
        idColor: "",
        cantidad: 0,
        cantidadPrenda: 0,
        observaciones: "NA",
      },
    ]);
  };
  const handleChangeInputTela = (id, event) => {
    const newInputFieldsTela = inputFieldsTela.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsTela(newInputFieldsTela);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.articulos_create ? (
        <div className="card container col-11">
          <h3 align="center">Pedido</h3>
          {vista == "Articulo" ? (
            <>
              <Form onSubmit={saveArticulos}>
                <Row>
                <Col md={3}>
                <Label className="mr-sm-2">Sociedad</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSociedad}
                  required
                  onChange={(e) => {
                    setSelectedSociedad(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Sociedad</option>
                  {sociedades
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </Col>
                  <Col md={3}>
                    <Label>Cliente</Label>
                    <Input
                      type="select"
                      value={selectedCliente}
                      onChange={(e) => {
                        setSelectedCliente(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {clientes
                        .sort((a, b) =>
                          a.nombre_comercial > b.nombre_comercial ? 1 : -1
                        )
                        .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Fecha Venta</Label>
                    <Input
                      type="date"
                      value={fecha}
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Modelo Provisional</Label>
                    <Input
                      type="text"
                      placeholder="Codigo"
                      value={codigo}
                      required
                      onChange={(e) => {
                        setCodigo(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={3}>
                    <Label className="mr-sm-2">Departamento</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedDepartamento}
                      required
                      onChange={(e) => {
                        setSelectedDepartamento(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {departamentos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  {/* <Col md={3}>
                    <Label className="mr-sm-2">Sub Departamento</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedSubDepartamento}
                      required
                      onChange={(e) => {
                        setSelectedSubDepartamento(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {subDepartamentos
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col> */}
                  <Col md={3}>
                    <Label className="mr-sm-2">Clase</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedClase}
                      required
                      onChange={(e) => {
                        setSelectedClase(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {clases
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>

                  <Col md={3}>
                    <Label className="mr-sm-2">Etiqueta</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedSubClase}
                      required
                      onChange={(e) => {
                        setSelectedSubClase(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {subClases
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label>Descripcion</Label>
                    <Input
                      type="text"
                      placeholder="Descripcion"
                      value={descripcion}
                      required
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Col>
                  {/* <Col md={3}>
                    <Label className="mr-sm-2">Marca</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedMarca}
                      required
                      onChange={(e) => {
                        setSelectedMarca(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {marcas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col> */}
                </Row>

                <Row>
                  <Col md={3}>
                    <Label className="mr-sm-2">Coleccion</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedColeccion}
                      required
                      onChange={(e) => {
                        setSelectedColeccion(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {colecciones
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Vendedor</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedVendedor}
                      required
                      onChange={(e) => {
                        setSelectedVendedor(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {colaboradores
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label className="mr-sm-2">Comprador</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedComprador}
                      required
                      onChange={(e) => {
                        setSelectedComprador(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {compradores
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label>Pedido Cliente</Label>
                    <Input
                      type="text"
                      placeholder="Pedido"
                      value={pedido}
                      required
                      onChange={(e) => {
                        setPedido(e.target.value);
                      }}
                    />
                  </Col>

                  </Row>

                <Row>
                  <Col md={3}>
                    <Label>Modelo Oficial</Label>
                    <Input
                      type="text"
                      placeholder="CodigoCliente"
                      value={codigoCliente}
                      required
                      onChange={(e) => {
                        setCodigoCliente(e.target.value);
                      }}
                    />
                  </Col>
                  {/* <Col md={3}>
                    <Label className="mr-sm-2">Descuento</Label>
                    <Input
                      // bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={descuento}
                      required
                      onChange={(e) => {
                        setDescuento(e.target.value);
                      }}
                    />
                  </Col> */}

                  <Col md={3}>
                    <Label>Observaciones Diseño</Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={4}>
                    <Label>Foto</Label>
                    <Input
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Agregar Colores
                    </Label>
                    <br />
                    <Button
                      color="success"
                      id="Colores"
                      onClick={(e) => toggleColores()}
                    >
                      <i class="fas fa-palette fa-2x "></i>
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Colores
                    </Label>
                    <br />
                    {arrayColoresNombre.map((a)=>{
                      return <li>{a}</li>
                    })}
                    
                  </Col>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Agregar Tallas
                    </Label>
                    <br />
                    <Button
                      color="success"
                      id="Tallas"
                      onClick={(e) => toggleTallas()}
                    >
                      <i class="fas fa-ruler-horizontal fa-2x "></i>
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Tallas
                    </Label>
                    <br />
                    {arrayTallasNombre.map((a)=>{
                      return <li>{a}</li>
                    })}
                    
                  </Col>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Agregar Procesos
                    </Label>
                    <br />
                    <Button
                      color="success"
                      id="Procesos"
                      onClick={(e) => toggleProcesos()}
                    >
                      <i class="fas fa-tshirt fa-2x "></i>
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Procesos
                    </Label>
                    <br />
                    {arrayProcesosNombre.map((a)=>{
                      return <li>{a}</li>
                    })}
                    
                  </Col>
                  </Row>
                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Siguiente
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Siguiente
                  </Button>
                )}
                <Button
                  href="/ListadoPedidos"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Form onSubmit={savePedido}>
                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Empresa</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedEmpresa}
                      required
                      onChange={(e) => {
                        setSelectedEmpresa(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {empresas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Cancelacion</Label>
                    <Input
                      type="date"
                      placeholder="Fecha Cancelacion"
                      value={fecha_cancelacion}
                      required
                      onChange={(e) => {
                        setFechaCancelacion(e.target.value);
                        let inicio = new Date(e.target.value);
                        let bla = inicio.setDate(inicio.getDate() - 41);
                        setFechaConfirmacion(moment(bla).format("YYYY-MM-DD"));
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Confirmacion</Label>
                    <Input
                      type="date"
                      placeholder="Fecha Confirmacion"
                      value={fecha_confirmacion}
                      required
                      onChange={(e) => {
                        setFechaConfirmacion(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Pendiente Fecha Conf</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={pendFechaConf}
                      required
                      onChange={(e) => {
                        setPendFechaConf(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Agregar Consumo
                    </Label>
                    <Button
                      color="success"
                      id="explosion"
                      onClick={(e) => explosiones()}
                    >
                      <i class="fas fa-tape"></i>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTALES {total_piezas} pzas. /{" "}
                      {"$" +
                        new Intl.NumberFormat("en-US").format(total_general)}
                    </h4>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={4}>
                    <Label className="mr-sm-2">Articulos</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={articulosGuardado[0].codigo}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Precio Venta</Label>
                    <Input
                      type="number"
                      value={precio}
                      required
                      onChange={(e) => {
                        jalaPrecio(e.target.value);
                      }}
                    />
                  </Col>
                  {/* <Col md={2}>
                    <Label className="mr-sm-2">Descuento</Label>
                    <Input
                      // bsSize="sm"
                      name="descuento"
                      type="number"
                      placeholder="Descuento"
                      value={descuento}
                      disabled
                      // required
                      // onChange={(e) => {
                      //   setDescuento(e.target.value);
                      // }}
                    />
                  </Col> */}
                  <Col md={2}>
                    <Label className="mr-sm-2">Precio Etiqueta</Label>
                    <Input
                      type="number"
                      value={precioEtiqueta}
                      required
                      onChange={(e) => {
                        setPrecioEtiqueta(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <Label>Proporcion</Label>
                  </Col>
                  {arrayProporcion.map((a) => {
                    return (
                      <>
                        <Col md={1}>
                          <Label>{a.tallaNombre}</Label>
                          {/* </Col>
              <Col md={1}> */}
                          <Input
                            bsSize="sm"
                            name="proporcion"
                            type="number"
                            value={a.proporcion}
                            required
                            onChange={(event) =>
                              handleChangeInputProporcion(a.id, event)
                            }
                          />
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <hr />
                {arrayColores.map((col) => {
                  return (
                    <>
                      <Row>
                        <Col md={10} className="tallas">
                          <Col md={1}>
                            {colores.map((a) => {
                              if (col == a._id) {
                                return <Label>{a.name}</Label>;
                              }
                            })}
                          </Col>
                          {inputFields.map((a) => {
                            if (col == a.idColor) {
                              return (
                                <>
                                  <Col md={1}>
                                    <Label>{a.tallaNombre}</Label>
                                    {/* </Col>
                                    <Col md={1}> */}
                                    <Input
                                      bsSize="sm"
                                      name="cantidad"
                                      type="number"
                                      value={a.cantidad}
                                      required
                                      onChange={(event) =>
                                        handleChangeInputCantidad(
                                          a.id,
                                          a.idColor,
                                          event
                                        )
                                      }
                                    />
                                  </Col>
                                </>
                              );
                            }
                          })}
                          {arrayTotalColor.map((tc) => {
                            if (tc.idColor == col) {
                              return (
                                <>
                                  <Col md={1}>
                                    <Label>Total</Label>
                                    {/* </Col>
                                  <Col md={1}> */}
                                    <Input
                                      bsSize="sm"
                                      name="cantidad"
                                      type="number"
                                      value={tc.totalColor}
                                      required
                                      onChange={(event) =>
                                        handleChangeInputTotalColor(
                                          tc.id,
                                          tc.idColor,
                                          event
                                        )
                                      }
                                    />
                                  </Col>
                                </>
                              );
                            }
                          })}
                        </Col>
                      </Row>
                    </>
                  );
                })}

                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTALES {total_piezas} pzas. /{" "}
                      {"$" +
                        new Intl.NumberFormat("en-US").format(total_general)}
                    </h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
              </Form>
            </>
          )}
          <br />

          <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
            <ModalHeader toggle={toggleColores}>
              <h4>Colores del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {colores
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((a, index) => {
                  if (!arrayColores.includes(a._id)) {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              coloresSeleccionados(
                                e.currentTarget.checked,
                                a._id,
                                a.name
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                  } else {
                    return <h5 className="subMenuTitulo">{a.name}</h5>;
                  }
                })}
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={modalTallas} toggle={toggleTallas}>
            <ModalHeader toggle={toggleTallas}>
              <h4>Tallas del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {tallas
                .sort((a, b) => (a.idTalla > b.idTalla ? 1 : -1))
                .map((a, index) => {
                  if (!arrayTallas.includes(a._id)) {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              tallasSeleccionados(
                                e.currentTarget.checked,
                                a._id,
                                a.name
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                  } else {
                    return <h5 className="subMenuTitulo">{a.name}</h5>;
                  }
                })}
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={modalProcesos} toggle={toggleProcesos}>
            <ModalHeader toggle={toggleProcesos}>
              <h4>Procesos del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {catalogoProcesos
                // .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((a, index) => {
                  if (a._id != "6363fb596cdd150aab0461cd") {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              procesosSeleccionados(
                                e.currentTarget.checked,
                                a._id,
                                a.name
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                  } else {
                    return <h5 className="subMenuTitulo">{a.name}</h5>;
                  }
                })}
            </ModalBody>
          </Modal>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios minutos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="xxl"
            isOpen={modalExplosiones}
            toggle={toggleExplosiones}
          >
            <ModalHeader toggle={toggleExplosiones}>
              <h4>Explosion Modelo</h4>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={3}>
                  <Label>Tela</Label>
                </Col>
                <Col md={2}>
                  <Label>Color</Label>
                </Col>
                <Col md={1}>
                  <Label>Metros Totales</Label>
                </Col>
                <Col md={1}>
                  <Label>Consumo Por Prenda</Label>
                </Col>
                <Col md={3}>
                  <Label>Observaciones</Label>
                </Col>
              </Row>

              {inputFieldsTela.map((a) => (
                <div key={a.id}>
                  <Row>
                    <Col md={3}>
                      <Input
                        bsSize="sm"
                        name="idTela"
                        type="select"
                        value={a.idTela}
                        required
                        onChange={(event) => {
                          handleChangeInputTela(a.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre} / {a.tiposTelas[0].name}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="idColor"
                        type="select"
                        value={a.idColor}
                        required
                        onChange={(event) => {
                          handleChangeInputTela(a.id, event);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {colores
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        value={a.cantidad}
                        onChange={(event) => {
                          handleChangeInputTela(a.id, event);
                        }}
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidadPrenda"
                        type="number"
                        value={a.cantidadPrenda}
                        onChange={(event) => {
                          handleChangeInputTela(a.id, event);
                        }}
                      ></Input>
                    </Col>
                    <Col md={3}>
                      <Input
                        bsSize="sm"
                        name="observaciones"
                        type="text"
                        value={a.observaciones}
                        onChange={(event) => {
                          handleChangeInputTela(a.id, event);
                        }}
                      ></Input>
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsTela.length === 1}
                        onClick={() => handleRemoveFieldsTelas(a.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={handleAddFieldsTelas}
                        tabindex="-1"
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <br />
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
