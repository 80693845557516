import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";

function ListadoParticipaciones() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SOCIEDADES = process.env.REACT_APP_URL_SOCIEDADES;
  const URL_PARTICIPACIONES = process.env.REACT_APP_URL_PARTICIPACIONES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
 
  const [mailTo, setMailTo] = useState("");
  const [nombre, setNombre] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);

  const [socios, setSocios] = useState([]);
  const [modalEditSocios, setModalEditSocios] = useState(false);
  const toggleEditSocios = () => setModalEditSocios(!modalEditSocios);
  const [nombreSociedad, setNombreSociedad] = useState("");
  const [sociosSociedad, setSociosSociedad] = useState([]);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  

  const [inputFields, setInputFields] = useState([{
    id: uuidv4(),
    socios: "",
    participacion: 0
  }]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

 
  useMemo(() => {
    axios
      .get(URL_SOCIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSociedades = response.data;
        // Array para pagination
        let arrayTabla = allSociedades
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                activo: a.is_active,
                nombre: a.nombre,
                observaciones: a.observaciones,}
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_SOCIOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSocios = response.data

        setSocios(allSocios);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    
  }, [user]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Sociedades`, 15, 40);
    doc.autoTable({
      head: [["Nombre"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Sociedades.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          id: a._id,
          Nombre: a.nombre,
      }}
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoSociedades";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoSociedades",
        sheetFilter: ["id", "Nombre"],
        sheetHeader: ["id", "Nombre"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.nombre,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Sociedades`, 20, 40);
    doc.autoTable({
      head: [["Nombre"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Sociedades",
          email: mailTo,
          fileName: "ListadoSociedades.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Sociedades.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    idEdit,
    nombre,
    observaciones,
  ) {
    setIdEdit(idEdit)
    setNombre(nombre)
    setObservaciones(observaciones)
    toggleEdit();
  }

  

  function editSociedades(event) {
    event.preventDefault();
    const URL_SOCIEDADES_EDIT = `${process.env.REACT_APP_URL_SOCIEDADES}/${idEdit}`;
        axios.patch(
          URL_SOCIEDADES_EDIT,
          {
            nombre,
            observaciones,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Sociedad",
            detalle: `${nombre}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }


  const headers = [
    { name: "Nombre ", field: "nombre", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) 
          );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo));
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importeRenta" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importeRenta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importeRenta")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);

  const sociosNoEnSociedad = socios.filter(
    (socio) => !sociosSociedad.some((sp) => sp.socios === socio._id)
  );

  function EditSocios(idProp, nombre) {
    setInputFields([
      {
        id: uuidv4(),
        socios: "",
        participacion: 0,
      },
    ]);
    setIdEdit(idProp);
    setNombreSociedad(nombre);
    axios
      .get(`${URL_PARTICIPACIONES}Sociedad/${idProp}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSociosOrden = response.data;
        let arrayTabla = allSociosOrden
          .map((a) => {
            return {
              id: a._id,
              socios: a.socios[0]._id,
              nombreSocio: a.socios[0].name,
              participacion: a.participacion,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setSociosSociedad(dataFinal);

      })
      .catch((err) => {
        console.log(err);
      });
    toggleEditSocios();
  }

  const handleChangeInputExistentesParticipacion = (id, event) => {
    const { value } = event.target;
    setSociosSociedad((prevSocios) =>
      prevSocios.map((socio) =>
        socio.id === id ? { ...socio, participacion: parseFloat(value) } : socio
      )
    );
  };

  function BuscaSocio(id, event) {
    socios.map((a) => {
      if (a._id == event.target.value) {
        let idSocio = a._id;
        handleChangeInputSocio(id, idSocio);
      }
    });
  }
  const handleChangeInputSocio = (id, idSocio) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.socios = idSocio;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.participacion = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        socios: "",
        participacion: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };



  const saveSociedad = async (event) => {
    event.preventDefault();

     // Calcular la suma total de las participaciones
  const totalParticipacion = 
  inputFields.reduce((acc, field) => acc + parseFloat(field.participacion), 0) +
  sociosSociedad.reduce((acc, socio) => acc + parseFloat(socio.participacion), 0);

// Verificar si la suma es 100
if (totalParticipacion !== 100) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "La suma de las participaciones debe ser 100%. Por favor, verifica los valores ingresados.",
  })
  return;
}

    toggleProgreso();

    let totalSocios = inputFields.length + sociosSociedad.length;
    try {
          inputFields.map((a) => {
            if (a.socios != "" && a.participacion > 0) {
              axios
                .post(
                  URL_PARTICIPACIONES,
                  {
                    sociedades: idEdit,
                    socios: a.socios,
                    participacion: a.participacion,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalSocios = totalSocios - 1;
                  if (totalSocios == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Editar Participaciones",
                          detalle: `${nombreSociedad}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
              // cerrar if
            } else {
              totalSocios = totalSocios - 1;
              if (totalSocios == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Editar Participaciones",
                      detalle: `${nombreSociedad}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            }
          });

          sociosSociedad.map((a) => {
            axios
              .patch(
                `${URL_PARTICIPACIONES}/${a.id}`,
                {
                  participacion: a.participacion,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalSocios = totalSocios - 1;
                if (totalSocios == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Participaciones",
                        detalle: `${nombreSociedad}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuSocios ? (
        <div className="card container col-sm-11">
          <Row>
            <Col md={8}>
              {/* {user.sociedades_create ? ( */}
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/SociedadesCreate"
                >
                  Nueva Sociedad
                </Button>
                <Button
                  size="sm"
              href="/MenuRetiros"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Sociedades</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Sociedades</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                {
                  return (
                    <tr>
                      <td> {p.nombre}</td>
                        <td>
                        {/* {user.sociedades_create ? ( */}
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  p._id,
                                  p.nombre,
                                  p.observaciones,
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                         

                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditSocios(p._id, p.nombre)}
                            >
                              <i class="fas fa-percent"></i>
                            </Button>
                            <Baja
                              idStatus={p._id}
                              is_active={p.activo}
                              URL_BAJA={process.env.REACT_APP_URL_SOCIEDADES}
                            />
                          </div>
                       
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Sociedad {nombre}</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
             
              <Col md={3}>
                <Label>Nombre </Label>
                <Input
                  type="text"
                  placeholder="Nombre "
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
            
            
            </Row>
            <Row>
             
           
              
              <Col md={9}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editSociedades}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalEditSocios}
        toggle={toggleEditSocios}
      >
        <ModalHeader toggle={toggleEditSocios}>
          <h4>Editar Socios {nombreSociedad}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={7}>
              <Label className="mr-sm-2">Socio</Label>
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Participacion</Label>
            </Col>
          </Row>

          {sociosSociedad
          .sort((a, b) => (a.nombreSocio > b.nombreSocio ? 1 : -1))
          .map((ao) => (
            <div key={ao.id}>
              <Row>
                <Col md={7}>
                  <Input
                    // bsSize="sm"
                    name="socio"
                    type="text"
                    value={ao.nombreSocio}
                    required
                    disabled
                  />
                </Col>

                <Col md={3}>
                  <Input
                    // bsSize="sm"
                    name="Participacion"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Participacion"
                    value={ao.participacion}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesParticipacion(ao.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

          {/* Agregar mas socios */}

          {inputFields.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                <Col md={7}>
                  <Input
                    // bsSize="sm"
                    name="socios"
                    type="select"
                    value={inputField.socios}
                    required
                    onChange={(event) => {
                      BuscaSocio(inputField.id, event);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {sociosNoEnSociedad
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.name}
                          </option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={3}>
                  <Input
                    // bsSize="sm"
                    name="Participacion"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Participacion"
                    value={inputField.participacion}
                    required
                    onChange={(event) =>
                      handleChangeInput(inputField.id, event)
                    }
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className="btn"
                    color="danger"
                    disabled={inputFields.length === 1}
                    onClick={() => handleRemoveFields(inputField.id)}
                    tabindex="-1"
                  >
                    <i class="fas fa-minus"></i>
                  </Button>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={handleAddFields}
                    tabindex="-1"
                  >
                    <i class="fas fa-plus"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          ))}

          <Row>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" 
          onClick={saveSociedad}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
      {loader}
    </>
  );
}

export default ListadoParticipaciones;
